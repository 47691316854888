import React from "react";
import { useTranslation } from "react-i18next";

//material ui
import { Box } from "@material-ui/core";

// component
import Btn from "components/Btns/Btn";

// img
import AboutImg from "assets/img/img-about-image.jpg";

// style
import styles from "../Ihic.module.scss";

const About = () => {
  const { t } = useTranslation();

  return (
    <Box className={`${styles["page-container"]}`}>
      <div className={styles["ihic-about"]}>
        <div className={styles["ihic-about-text"]}>
          <p className={styles["ihic-about-text__text"]}>
            {t("about_text_about")}
          </p>
          <p
            className={`${styles["ihic-about-text__text"]} ${styles["ihic-about-text__text-mb"]}`}
          >
            {t("about_text_about_2")}
          </p>
          <Btn icon="blank" to="https://www.ihic.jp/">
            {t("about_btn_to_ihic")}
          </Btn>
        </div>

        <p className={styles["ihic-about-img"]}>
          <img
            onContextMenu={(e) => {
              e.preventDefault();
              e.stopPropagation();
              return false;
            }}
            src={AboutImg}
            alt=""
            className={styles["ihic-about-img__img"]}
          />
          <span
            className={styles["ihic-about-img__text"]}
            dangerouslySetInnerHTML={{ __html: t("ihic_img_alt") }}
          />
        </p>
      </div>
    </Box>
  );
};

export default About;
