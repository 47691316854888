import React from "react";
import { Link } from "react-router-dom";

// style
import styles from "./Btn.module.scss";

// img
import iconArrowCircle from "assets/img/icon-arrow-circle.png";

const ListBtn = ({ children = "default", to = "/" }) => {
  return (
    <>
      <div className={styles["btn-list"]}>
        <Link to={to}>
          {children}
          <img src={iconArrowCircle} alt={children} />
        </Link>
      </div>
    </>
  );
};

export default ListBtn;
