import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { useRest, useRestRefresh } from "@karpeleslab/react-klbfw-hooks";

// component
import InnerLayout from "components/Layout/InnerLayout";
import PageHeader from "components/PageHeader/PageHeader";
import Helmet from "components/Helmet/Helmet";
import ListNews from "components/List/ListNews";
import SelectForm from "components/Forms/Select";
import Pagination from "components/Pagination/Pagination";
import Loading from "components/Loading/Loading";

// style
import styles from "./News.module.scss";

// constant
import { NEWS_PAGE_COUNT } from "const/const";

const News = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const { yearNum, pageNum } = useParams();

  // year
  const currentYear = moment().format("YYYY");
  const lastYear = moment(currentYear).subtract(1, "y").format("YYYY");
  const [year, setYear] = useState(yearNum === void 0 ? currentYear : yearNum);

  //pages
  const [currentPage, setCurrentPage] = useState(
    pageNum === void 0 ? 1 : pageNum
  );

  const [news, setNews] = useRest("Content/Cms/@information:search", {
    page_no: currentPage,
    results_per_page: NEWS_PAGE_COUNT,
    sort: "published:desc",
    query: {
      published: { $lte: `${year}-12-31`, $gte: `${year}-01-01` },
      tag: "news",
    },
  });

  const newsRefresh = useRestRefresh("Content/Cms/@information:search", {
    page_no: currentPage,
    results_per_page: NEWS_PAGE_COUNT,
    sort: "published:desc",
    query: {
      published: { $lte: `${year}-12-31`, $gte: `${year}-01-01` },
      tag: "news",
    },
  });

  useEffect(() => {
    setNews(null);
    setCurrentPage(pageNum === void 0 ? 1 : pageNum);
    setYear(yearNum === void 0 ? moment().format("YYYY") : yearNum);
  }, [location.pathname, pageNum, yearNum]);

  useEffect(() => {
    if (news === null) {
      newsRefresh();
    }
  }, [currentPage, news, newsRefresh]);

  return (
    <InnerLayout
      breadcrumb={[
        {
          to: "/news",
          text: t("news_sub_title"),
        },
      ]}
    >
      <Helmet>
        <title>{`${t("news_sub_title")} - ${t("common_ncih")}`}</title>
        <meta
          property="og:title"
          content={`${t("news_sub_title")} - ${t("common_ncih")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("news_sub_title")} - ${t("common_ncih")}`}
        />
      </Helmet>
      <PageHeader title={t("news_title")} subTitle={t("news_sub_title")} />
      <div className={styles["news-container"]}>
        <div className={styles["news-header"]}>
          <h2 className={styles["news-header-title"]}>{`${year}年`}</h2>
          <SelectForm
            handleChange={(value) => {
              setYear(value);
              history.push(
                `/${location.pathname.split("/")[1]}/${value}/page-1`
              );
            }}
            value={year}
            classVal={styles["news-header-select"]}
            array={[
              { label: `${currentYear}年`, val: currentYear },
              { label: `${lastYear}年`, val: lastYear },
            ]}
          />
        </div>
        {news === null && <Loading />}
        {news !== null && <ListNews vals={news?.data.data} />}
        {news !== null && (
          <Pagination
            setCurrentPage={setCurrentPage}
            defaultPage={currentPage}
            path={`/${year}`}
            count={news?.paging.page_max}
          />
        )}
      </div>
    </InnerLayout>
  );
};

export default News;
