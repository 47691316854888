import React from "react";
import { useTranslation } from "react-i18next";

//material ui
import { Grid } from "@material-ui/core";

// components
import Btn from "components/Btns/Btn";
import TypoGroup from "components/Typography/TypoGroup";

// img
import title from "assets/img/title-world.png";
import thumd from "assets/img/img-index-world.png";

// style
import styles from "./World.module.scss";

const World = () => {
  const { t } = useTranslation();
  return (
    <div className={styles["world-container"]}>
      <Grid container>
        <Grid
          container
          alignItems="flex-end"
          item
          xs={12}
          md={4}
          lg={4}
          className={styles["world-img"]}
        >
          <img src={thumd} alt="" />
        </Grid>
        <Grid item xs={12} md={8} lg={8} className={styles["world-info"]}>
          <TypoGroup
            titleImg={title}
            title={t("world_title")}
            subtitle={t("common_world")}
            variant="h2"
            maxWidth={330}
            text={t("index_worldt_text")}
            align="left"
            textAlign="left"
          />
          <div className={styles["world-info-img"]}>
            <img src={thumd} alt="" />
          </div>
          <div className={styles["world-info-btn"]}>
            <Btn to="/world">{t("common_btn_view_alll")}</Btn>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default World;
