import React from "react";
import { useTranslation } from "react-i18next";

//material ui
import { Typography, Box } from "@material-ui/core";

// style
import styles from "../Ihic.module.scss";

const Content = () => {
  const { t } = useTranslation();

  return (
    <Box
      className={`${styles["ihic-content-container"]} ${styles["page-container"]}`}
    >
      <Typography className={styles["ihic-content-title"]} variant="h2">
        {t("about_content_title")}
      </Typography>
      <div className={styles["ihic-content-list-container"]}>
        <ol className={styles["ihic-content-list"]}>
          <li className={styles["ihic-content-list-item"]}>
            {t("about_think_tank")}
          </li>
          <li className={styles["ihic-content-list-item"]}>
            {t("about_research")}
          </li>
          <li className={styles["ihic-content-list-item"]}>
            {t("about_management")}
          </li>
          <li className={styles["ihic-content-list-item"]}>
            {t("about_training")}
          </li>
        </ol>
        <ol
          className={styles["ihic-content-list"]}
          style={{
            counterReset: "ol 4",
          }}
        >
          <li className={styles["ihic-content-list-item"]}>
            {t("about_strategy")}
          </li>
          <li className={styles["ihic-content-list-item"]}>
            {t("about_digital")}
          </li>
          <li className={styles["ihic-content-list-item"]}>
            {t("about_local")}
          </li>
        </ol>
      </div>
    </Box>
  );
};

export default Content;
