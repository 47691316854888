import React from "react";
import clsx from "clsx";

// material ui

// styles
import styles from "./Btn.module.scss";

// img
import iconBlankWhite from "assets/img/icon-blank-white.png";

const BtnLink = ({
  type = "primary",
  children = "default",
  to = "/",
  className = null,
}) => {
  return (
    <a
      className={clsx(styles["btn"], {
        [styles["btn-primary"]]: type === "primary",
        [styles["btn-secondary"]]: type === "secondary",
        [styles["btn-danger"]]: type === "danger",
        [className]: className !== null,
      })}
      href={to}
      target="_blank"
      rel="noreferrer"
      style={{
        textDecoration: "none",
      }}
    >
      {children}
      <span
        style={{
          marginLeft: "20px",
        }}
      >
        <img src={iconBlankWhite} />
      </span>
    </a>
  );
};

export default BtnLink;
