import React from "react";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";

//material ui
import { Box, Typography } from "@material-ui/core";

// component
import InnerLayout from "components/Layout/InnerLayout";
import PageHeader from "components/PageHeader/PageHeader";
import Helmet from "components/Helmet/Helmet";
import Btn from "components/Btns/Btn";

// style
import styles from "./World.module.scss";

// img
import NeilImg from "assets/img/img-world-01.png";
import StuartImg from "assets/img/img-world-02.png";

const News = () => {
  const { t } = useTranslation();

  return (
    <InnerLayout
      breadcrumb={[
        {
          to: "/world",
          text: t("world_sub_title"),
        },
      ]}
    >
      <Helmet>
        <title>{`${t("world_sub_title")} - ${t("common_ncih")}`}</title>
        <meta
          property="og:title"
          content={`${t("world_sub_title")} - ${t("common_ncih")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("world_sub_title")} - ${t("common_ncih")}`}
        />
      </Helmet>
      <PageHeader title={t("world_title")} subTitle={t("world_sub_title")} />

      <Box className={`${styles["page-container"]}`}>
        <Typography className={styles["world-name"]} variant="h2">
          {t("world_name_neil")}
        </Typography>
        <p className={styles["world-name-title"]}>
          {t("world_name_neil_title")}
        </p>
        <div className={styles["world-list"]}>
          <figure className={styles["world-list-img"]}>
            <img src={NeilImg} alt={t("world_name_neil")} />
          </figure>
          <div className={styles["world-list-text-container"]}>
            <p
              className={styles["world-list-text"]}
              dangerouslySetInnerHTML={{ __html: t("world_neil_text") }}
            />
          </div>
        </div>
      </Box>

      <Box className={`${styles["page-container"]}`}>
        <Typography className={styles["world-name"]} variant="h2">
          {t("world_name_stuart")}
        </Typography>
        <p className={styles["world-name-title"]}>
          {t("world_name_stuart_title")}
        </p>
        <div className={styles["world-list"]}>
          <figure className={styles["world-list-img"]}>
            <img src={StuartImg} alt={t("world_name_stuart")} />
          </figure>
          <div className={styles["world-list-text-container"]}>
            <p className={styles["world-list-text-title"]}>
              {t("world_stuart_text_title")}
            </p>
            <p
              className={styles["world-list-text"]}
              dangerouslySetInnerHTML={{ __html: t("world_stuart_text") }}
            />
          </div>
        </div>
      </Box>

      <Box className={`${styles["page-container"]}`}>
        <Typography className={styles["world-name"]} variant="h2">
          {t("world_name_dietrich")}
        </Typography>
        <div className={styles["world-video-container"]}>
          <div className={styles["world-video-pdf"]}>
            <dl className={styles["world-video-pdf-list-container"]}>
              <div className={styles["world-video-pdf-list"]}>
                <dt className={styles["world-video-pdf-list-title"]}>
                  {t("world_pdf_text1")}
                </dt>
                <dd className={styles["world-video-pdf-list-text"]}>
                  <Btn fileType="pdf" to="/pdf/dr_soyez_1.pdf">
                    {t("common_btn_pdf")}
                  </Btn>
                </dd>
              </div>
              <div className={styles["world-video-pdf-list"]}>
                <dt className={styles["world-video-pdf-list-title"]}>
                  {t("world_pdf_text2")}
                </dt>
                <dd className={styles["world-video-pdf-list-text"]}>
                  <Btn fileType="pdf" to="/pdf/dr_soyez_2.pdf">
                    {t("common_btn_pdf")}
                  </Btn>
                </dd>
              </div>
              <div className={styles["world-video-pdf-list"]}>
                <dt className={styles["world-video-pdf-list-title"]}>
                  {t("world_pdf_text3")}
                </dt>
                <dd className={styles["world-video-pdf-list-text"]}>
                  <Btn fileType="pdf" to="/pdf/dr_soyez_3.pdf">
                    {t("common_btn_pdf")}
                  </Btn>
                </dd>
              </div>
              <div className={styles["world-video-pdf-list"]}>
                <dt className={styles["world-video-pdf-list-title"]}>
                  {t("world_pdf_text4")}
                </dt>
                <dd className={styles["world-video-pdf-list-text"]}>
                  <Btn fileType="pdf" to="/pdf/dr_soyez_4.pdf">
                    {t("common_btn_pdf")}
                  </Btn>
                </dd>
              </div>
            </dl>
          </div>
          <div className={styles["world-video-list"]}>
            <div className={styles["world-video-list-item"]}>
              <ReactPlayer
                className={styles["world-video-list-item-video"]}
                url="https://www.youtube.com/watch?v=tuvfZsPyn6s"
                width="100%"
                height="100%"
              />
            </div>
            <div className={styles["world-video-list-item"]}>
              <ReactPlayer
                className={styles["world-video-list-item-video"]}
                url="https://www.youtube.com/watch?v=d7PvJNTYlgo"
                width="100%"
                height="100%"
              />
            </div>
            <div className={styles["world-video-list-item"]}>
              <ReactPlayer
                className={styles["world-video-list-item-video"]}
                url="https://www.youtube.com/watch?v=j0QjRnMhSpc"
                width="100%"
                height="100%"
              />
            </div>
            <div className={styles["world-video-list-item"]}>
              <ReactPlayer
                className={styles["world-video-list-item-video"]}
                url="https://www.youtube.com/watch?v=Zgd5MMA4QzM"
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      </Box>
    </InnerLayout>
  );
};

export default News;
