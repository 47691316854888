import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { rest } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";

// material ui
import { Typography, Box } from "@material-ui/core";

// component
import InnerLayout from "components/Layout/InnerLayout";
import PageHeader from "components/PageHeader/PageHeader";
import Helmet from "components/Helmet/Helmet";
import Loading from "components/Loading/Loading";
import Toast, { error } from "components/Toast/Toast";
import Button from "components/Btns/Btn";
import CartList from "components/List/CartList";
import LoadingOverLay from "components/Loading/LoadingOverLay";
import BillingInputs from "components/Billing/BillingInputs";

// style
import styles from "./Checkout.module.scss";

const Checkout = () => {
  const { t } = useTranslation();
  const [user] = useRest("User:get");
  const [cart] = useRest("Catalog/Cart/@");
  const history = useHistory();

  // load
  const [load, setLoad] = useState(false);

  // form
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [zip, setZip] = useState("");
  const [province, setProvince] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [geoVal] = useRest("ThirdParty/Geoip:lookup");
  const [Countrys] = useRest("Country");
  const [formEnabled, setFormEnabled] = useState(false);

  // country
  useEffect(() => {
    if (geoVal !== null) {
      setCountry(geoVal.data.country.iso_code);
    }
  }, [geoVal]);

  // form input check
  useEffect(() => {
    if (
      !lName.trim() ||
      !fName.trim() ||
      !zip.trim() ||
      !province.trim() ||
      !city.trim() ||
      !address.trim() ||
      !phone.trim() ||
      !country.trim()
    ) {
      setFormEnabled(false);
      return;
    }
    setFormEnabled(true);
  }, [lName, fName, zip, country, province, city, address, phone]);

  const createLocation = (e) => {
    if (e) e.preventDefault();

    setLoad(true);

    rest("User/@/Location", "POST", {
      First_Name: fName,
      Last_Name: lName,
      Zip: zip,
      Country__: country,
      Address: address,
      City: city,
      Province: province,
      Contact_Phone: phone,
    })
      .then((data) => {
        rest("User/@:setDefaultLocation", "POST", {
          User_Location__: data.data.User_Location__,
          type: "Billing",
        })
          .then(() => {
            rest("Catalog/Cart/@:createOrder", "POST")
              .then((data) => {
                setLoad(false);
                history.push("/order/" + data.data.Order__);
              })
              .catch((data) => {
                setLoad(false);
                setTimeout(() => {
                  error(data.message, {
                    position: "top-center",
                    autoClose: 3000,
                  });
                }, 100);
              });
          })
          .catch((data) => {
            setLoad(false);
            setTimeout(() => {
              error(data.message, {
                position: "top-center",
                autoClose: 3000,
              });
            }, 100);
          });
      })
      .catch((err) => {
        setLoad(false);
        setTimeout(() => {
          error(err.message, {
            position: "top-center",
            autoClose: 3000,
          });
        }, 100);
      });
  };

  return (
    <InnerLayout
      breadcrumb={[
        {
          to: "",
          text: t("checkout_sub_title"),
        },
      ]}
    >
      <Helmet>
        <title>{`${t("checkout_sub_title")} - ${t("common_ncih")}`}</title>
        <meta
          property="og:title"
          content={`${t("checkout_sub_title")} - ${t("common_ncih")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("checkout_sub_title")} - ${t("common_ncih")}`}
        />
      </Helmet>
      <PageHeader
        title={t("checkout_title")}
        subTitle={t("checkout_sub_title")}
      />

      <Box className={`${styles["page-container"]}`}>
        {cart === null && user === null && <Loading />}
        {cart !== null && cart?.data.products.length !== 0 && user !== null && (
          <>
            <Typography className={styles["checkout-title"]} variant="h5">
              {t("checkout_cart_title")}
            </Typography>
            <CartList cart={cart} deleteBtn={false} />
          </>
        )}
      </Box>
      <Box className={`${styles["page-container"]}`}>
        <Typography className={styles["checkout-title"]} variant="h5">
          {t("checkout_info_title")}
        </Typography>
        <BillingInputs
          fName={fName}
          setFName={setFName}
          lName={lName}
          setLName={setLName}
          zip={zip}
          setZip={setZip}
          province={province}
          setProvince={setProvince}
          city={city}
          setCity={setCity}
          address={address}
          setAddress={setAddress}
          phone={phone}
          setPhone={setPhone}
          country={country}
          setCountry={setCountry}
          geoVal={geoVal}
          Countrys={Countrys}
          formEnabled={formEnabled}
          setFormEnabled={setFormEnabled}
          load={load}
        />
        <div className={styles["checkout-btns"]}>
          <Button
            disabled={!formEnabled || load}
            onClick={() => {
              createLocation();
            }}
          >
            {t("checkout_to_order")}
          </Button>
        </div>
      </Box>
      {load && <LoadingOverLay />}
      <Toast duration={5000} />
    </InnerLayout>
  );
};

export default Checkout;
