import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// conponents
import Header from "components/Header/Header";
import Helmet from "components/Helmet/Helmet";

const Layout = ({ children }) => {
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      <Helmet>
        <title>{t("common_title")}</title>
        <meta name="description" content={t("common_desc")} />
        <meta property="og:title" content={t("common_title")} />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={t("common_desc")} />
        <meta
          property="og:url"
          content={`https://www.ncih.jp${location.pathname}`}
        />
        <meta property="og:site_name" content={t("common_title")} />
        <meta
          property="og:image"
          content="https://www.ncih.jp/img/og-img.jpg"
        />
      </Helmet>
      <Header />
      {children}
    </div>
  );
};

export default Layout;
