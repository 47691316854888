import React from "react";
import { useTranslation } from "react-i18next";

//material ui
import { Box, Typography } from "@material-ui/core";

// style
import styles from "./ContactInfo.module.scss";

const ContactInfo = () => {
  const { t } = useTranslation();

  return (
    <Box className={styles["page-container"]}>
      <div className={styles["contact-info-container"]}>
        <Typography className={styles["contact-info-title"]} variant="h2">
          {t("contactinfo_title")}
        </Typography>
        <p className={styles["contact-info-title-sub"]}>
          {t("contactinfo_organization_name")}
        </p>
        <p className={styles["contact-info-address"]}>
          {t("contactinfo_address")}
        </p>
        <dl className={styles["contact-info-list"]}>
          <div className={styles["contact-info-item"]}>
            <dt className={styles["contact-info-item-label"]}>
              {t("contactinfo_tell_label")}
            </dt>
            <dd className={styles["contact-info-item-text"]}>
              {t("contactinfo_tell")}
            </dd>
          </div>
          <div className={styles["contact-info-item"]}>
            <dt className={styles["contact-info-item-label"]}>
              {t("contactinfo_fax_label")}
            </dt>
            <dd className={styles["contact-info-item-text"]}>
              {t("contactinfo_fax")}
            </dd>
          </div>
          <div
            className={`${styles["contact-info-item"]} ${styles["contact-info-item-email"]}`}
          >
            <dt className={styles["contact-info-item-label"]}>
              {t("contactinfo_email_label")}
            </dt>
            <dd
              className={`${styles["contact-info-item-text"]} ${styles["contact-info-item-text-email"]}`}
            >
              {t("contactinfo_email")}
            </dd>
          </div>
        </dl>
      </div>
    </Box>
  );
};

export default ContactInfo;
