import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

// material ui
import { Typography } from "@material-ui/core";

// component
import Tags from "components/Tags/Tags";

// utils
import { breakedText } from "utils/BreakedText";
import { buildDate } from "utils/BuildDate";

// img
import iconArrowBack from "assets/img/icon-arrow-back.png";
import Bg from "assets/img/bg-index-hero.png";

// styles
import styles from "./Cms.module.scss";

const DetailHeader = ({ article = null }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <div className={styles["cms-header"]}>
        <Typography
          align="center"
          className={styles["cms-header-title"]}
          variant="h2"
        >
          {breakedText(t("information_title"))}
        </Typography>
        <p
          className={styles["cms-header-back"]}
          onClick={() => {
            history.goBack();
          }}
        >
          <img src={iconArrowBack} alt="" />
          <span>{t("common_btn_back_list")}</span>
        </p>
      </div>
      <div
        className={`${styles["cms-info-container"]} ${styles["page-container"]}`}
        style={{
          backgroundImage: `url(${
            article.Top_Drive_Item__ !== null
              ? article.Top_Drive_Item.Media_Image.Url
              : Bg
          })`,
        }}
      >
        <div className={styles["cms-info"]}>
          <div className={styles["cms-info-date"]}>
            <div className={styles["cms-info-date-top"]}>
              {buildDate(parseInt(article.Published.unixms), "YYYY MM")}
            </div>
            <div>{buildDate(parseInt(article.Published.unixms), "DD")}</div>
          </div>
          <Typography className={styles["cms-info-title"]} variant="h1">
            {article.Title}
          </Typography>
          <Tags
            className={{
              container: styles["cms-info-tags"],
              item: styles["cms-info-tags-item"],
            }}
            tag={null}
            path="information"
            Tags={article.Content_Cms_Entry.Tags}
          />
        </div>
      </div>
    </>
  );
};

export default DetailHeader;
