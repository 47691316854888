import React from "react";

// conponents
import Footer from "components/Footer/Footer";

// style
import styles from "./Layout.module.scss";

const InnerLayout = ({ children, breadcrumb = [] }) => {
  return (
    <>
      <main className={styles["container"]}>{children}</main>
      <Footer breadcrumb={breadcrumb} />
    </>
  );
};

export default InnerLayout;
