import React from "react";

// style
import styles from "./List.module.scss";

const ArchiveList = ({ array = [] }) => {
  return (
    <div className={styles["archives-list"]}>
      {array.map((val, index) => {
        return (
          <div key={index} className={styles["archives-item"]}>
            <figure className={styles["archives-item-img"]}>
              <img src={val.img} alt={val.text} />
              <figcaption className={styles["archives-item-caption"]}>
                {val.text}
              </figcaption>
            </figure>
          </div>
        );
      })}
    </div>
  );
};

export default ArchiveList;
