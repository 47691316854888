import React from "react";

// material ui
import { ChevronRight, ChevronLeft } from "@material-ui/icons";
import Button from "@material-ui/core/Button";

// jss
import styles from "./Btn.module.scss";

export const ArrowLeft = ({ action }) => {
  return (
    <Button className={styles["btn-circle"]} onClick={() => action()}>
      <ChevronLeft />
    </Button>
  );
};

export const ArrowRight = ({ action = null }) => {
  return (
    <Button className={styles["btn-circle"]} onClick={() => action()}>
      <ChevronRight />
    </Button>
  );
};
