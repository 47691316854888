import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

// material ui
import { Modal, Typography } from "@material-ui/core";

// component
import InputForm from "components/Forms/Input";
import Button from "components/Btns/Btn";

// styles
import styles from "./Password.module.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    backgroundColor: "#fff",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(6),
  },
}));

const Password = ({ open, submit, setOpen, disabled }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (!open) {
      setPassword("");
    }
  }, [open]);

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.paper}>
        <Typography align="left" variant="h6" component="h2">
          {t("mypage_modal_title")}
        </Typography>
        <p>{t("mypage_modal_text")}</p>
        <InputForm
          type="password"
          value={password}
          name="user-password"
          label={t("mypage_modal_password")}
          placeholder={t("input_field_placeholder", {
            text: t("mypage_modal_password"),
          })}
          handleChange={(event) => {
            setPassword(event.target.value);
          }}
          required={true}
          disabled={disabled}
        />
        <div className={styles["password-btns-group"]}>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            iconPosition="noting"
            disabled={disabled}
          >
            {t("mypage_modal_btn_close")}
          </Button>
          <Button
            onClick={() => {
              submit(password);
            }}
            iconPosition="noting"
            disabled={password.length === 0 || disabled}
          >
            {t("common_save")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default Password;
