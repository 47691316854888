import React, { useEffect, useState } from "react";
import { useWindowWidth } from "@react-hook/window-size";

// material ui
import { Typography } from "@material-ui/core";

// component
import HandTitle from "./HandTitle";
import SubTitle from "./SubTitle";

// style
import styles from "./Typography.module.scss";

const TypoGroup = ({
  titleImg = null,
  maxWidth = 300,
  title = "defaul",
  subtitle = "default",
  variant = "h1",
  text = null,
  align = "center",
  textAlign = "center",
}) => {
  const [alignStatus, setAlignStatus] = useState(align);

  const onlyWidth = useWindowWidth();

  useEffect(() => {
    if (onlyWidth <= 945) {
      setAlignStatus("center");
    } else {
      setAlignStatus(align);
    }
  }, [onlyWidth]);

  useEffect(() => {
    if (onlyWidth <= 959) {
      setAlignStatus("center");
    } else {
      setAlignStatus(align);
    }
  }, []);

  return (
    <div className={styles["title-group"]}>
      <HandTitle
        titleImg={titleImg}
        title={title}
        variant={variant}
        maxWidth={maxWidth}
        align={alignStatus}
      />
      <SubTitle align={alignStatus}>{subtitle}</SubTitle>
      {text !== null && (
        <Typography className={styles["title-group-text"]} align={textAlign}>
          {text}
        </Typography>
      )}
    </div>
  );
};

export default TypoGroup;
