import React from "react";
import { useTranslation } from "react-i18next";

//material ui
import { Box } from "@material-ui/core";

// component
import TypoGroup from "components/Typography/TypoGroup";

// img
import title from "assets/img/title-greeting.png";
import kato from "assets/img/img-about-profile-president.jpg";

// style
import styles from "../Ihic.module.scss";

const Greeting = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className={styles["ihic-booking-title"]}>
        <TypoGroup
          titleImg={title}
          title={t("about_title_greeting")}
          subtitle={t("about_title_sub_greeting")}
          variant="h2"
          maxWidth={332}
        />
      </div>
      <div className={styles["ihic-booking-container"]}>
        <Box
          className={`${styles["page-container"]} ${styles["ihic-booking-container-inner"]}`}
        >
          <p>{t("about_greeting_text_1")}</p>
          <p>{t("about_greeting_text_2")}</p>
          <p>{t("about_greeting_text_3")}</p>
          <div className={styles["ihic-booking-profile"]}>
            <img
              className={styles["ihic-booking-profile-img"]}
              src={kato}
              alt={t("about_greeting_name")}
            />
            <div className={styles["ihic-booking-profile-text"]}>
              <dl>
                <dt>{t("about_greeting_position")}</dt>
                <dd>{t("about_greeting_name")}</dd>
              </dl>
              <p>{t("about_greeting_profile")}</p>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default Greeting;
