import React from "react";
import { useTranslation } from "react-i18next";

// component
import InnerLayout from "components/Layout/InnerLayout";
import PageHeader from "components/PageHeader/PageHeader";
import Btn from "components/Btns/Btn";

// styles
import styles from "./Logout.module.scss";

const Logout = () => {
  const { t } = useTranslation();

  return (
    <InnerLayout
      breadcrumb={[
        {
          to: "/logout",
          text: t("logout_title"),
        },
      ]}
    >
      <PageHeader title={t("logout_title")} subTitle={t("logout_sub_title")} />
      <div className={styles["logout-container"]}>
        <p className={styles["logout-text"]}>{t("logout_text")}</p>
        <Btn to="/">{t("common_btn_top")}</Btn>
      </div>
    </InnerLayout>
  );
};

export default Logout;
