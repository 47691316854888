import React, { useState, useEffect } from "react";
import { rest } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";
import Toast, { error } from "components/Toast/Toast";

// component
import InnerLayout from "components/Layout/InnerLayout";
import PageHeader from "components/PageHeader/PageHeader";
import Helmet from "components/Helmet/Helmet";
import Input from "components/Forms/Input";
import TextAreaForm from "components/Forms/TextArea";
import CheckBox from "components/Forms/CheckBox";
import Button from "components/Btns/Btn";
import LoadingOverLay from "components/Loading/LoadingOverLay";

// utils
import { validateEmail } from "utils/Validation";

// style
import styles from "./Support.module.scss";

const Support = () => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [massage, setMessage] = useState("");
  const [privacy, setPrivacy] = useState(false);
  const [disabled, setDisabled] = useState(true);

  // send
  const [sending, setSending] = useState(false);
  const [sendComp, setSendComp] = useState(false);

  const send = () => {
    setSending(true);

    const query = {
      Email: email,
      Name: name,
      To: "@support",
      Subject: "応援メッセージ",
      Message: massage,
    };

    rest("Support/Ticket", "POST", query)
      .then(() => {
        setSendComp(true);
        setSending(false);
      })
      .catch((err) => {
        setSending(false);
        error(t(`error_${err.token}`), false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  useEffect(() => {
    if (!validateEmail(email) || !privacy || !email.trim() || !massage.trim()) {
      setDisabled(true);
      return;
    }
    setDisabled(false);
  }, [email, name, massage, privacy]);

  return (
    <>
      <InnerLayout
        breadcrumb={[
          {
            to: "/support",
            text: t("support_sub_title"),
          },
        ]}
      >
        <Helmet>
          <title>{`${t("support_sub_title")} - ${t("common_ncih")}`}</title>
          <meta
            property="og:title"
            content={`${t("support_sub_title")} - ${t("common_ncih")}`}
          />
          <meta
            property="og:site_name"
            content={`${t("support_sub_title")} - ${t("common_ncih")}`}
          />
        </Helmet>
        <PageHeader
          title={t("support_title")}
          subTitle={t("support_sub_title")}
        />
        <div className={styles["support-container"]}>
          {sendComp && (
            <>
              <p className={styles["support-comp-text"]}>
                {t("support_send_comp_txt")}
              </p>
              <div className={styles["support-btn"]}>
                <Button to="/">{t("common_btn_top")}</Button>
              </div>
            </>
          )}
          {!sendComp && (
            <>
              <Input
                name="name"
                label={t("support_label_name")}
                handleChange={(e) => {
                  setName(e.target.value);
                }}
                value={name}
                placeholder={t("support_label_name")}
                helperText=""
                disabled={sending}
              />
              <Input
                name="email"
                label={t("support_label_email")}
                handleChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
                placeholder={t("support_label_email")}
                helperText=""
                type="email"
                disabled={sending}
              />
              <TextAreaForm
                name="message"
                label={t("support_label_massage")}
                handleChange={(e) => {
                  setMessage(e.target.value);
                }}
                value={massage}
                placeholder=""
                helperText=""
                disabled={sending}
              />
              <div className={styles["support-privacy-container"]}>
                <CheckBox
                  label={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t("support_privacy", { privacy: "/privacy" }),
                      }}
                    ></span>
                  }
                  handleChange={(e) => {
                    setPrivacy(e.target.checked);
                  }}
                  value={privacy}
                  disabled={sending}
                />
              </div>
              <div className={styles["support-btn"]}>
                <Button onClick={send} disabled={disabled || sending}>
                  {t("support_btn")}
                </Button>
              </div>
            </>
          )}
        </div>
        {sending && <LoadingOverLay />}
      </InnerLayout>
      <Toast duration={5000} />
    </>
  );
};

export default Support;
