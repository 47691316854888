import React from "react";
import { useTranslation } from "react-i18next";

//material ui
import { Box } from "@material-ui/core";

// component
import Btn from "components/Btns/Btn";

// style
import styles from "./ContactInfo.module.scss";

const ContactInfoIhic = () => {
  const { t } = useTranslation();

  return (
    <Box className={styles["page-container"]}>
      <div
        className={`${styles["contact-info-container"]} ${styles["contact-info-container-ihic"]}`}
      >
        <p className={styles["contact-info-title-sub"]}>
          {t("contactinfo_title_ihic")}
        </p>
        <p className={styles["contact-info-address"]}>
          {t("contactinfo_address_ihic")}
        </p>
        <dl
          className={`${styles["contact-info-list"]} ${styles["contact-info-list-ihic"]}`}
        >
          <div
            className={`${styles["contact-info-item"]} ${styles["contact-info-item-ihic"]}`}
          >
            <dt className={styles["contact-info-item-label"]}>
              {t("contactinfo_tell_label")}
            </dt>
            <dd className={styles["contact-info-item-text"]}>
              {t("contactinfo_tell_ihic")}
            </dd>
          </div>
        </dl>
        <Btn icon="blank" to="https://www.ihic.jp/">
          {t("about_btn_to_ihic")}
        </Btn>
      </div>
    </Box>
  );
};

export default ContactInfoIhic;
