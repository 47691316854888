import React, { useRef } from "react";
import Slider from "react-slick";

// component
import Card from "./Card";
import { ArrowRight, ArrowLeft } from "components/Btns/Arrow";

// style
import styles from "./Cards.module.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Cards = ({ vals = null, path = "information" }) => {
  const slider = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 959,
        settings: "unslick",
      },
    ],
  };

  const slidePrev = () => {
    slider.current.slickPrev();
  };

  const slideNext = () => {
    slider.current.slickNext();
  };

  return (
    <div className={styles["cards"]}>
      {vals.length > 1 && (
        <div className={styles["cards-btns"]}>
          <ArrowLeft action={slidePrev} />
          <ArrowRight action={slideNext} />
        </div>
      )}

      <Slider {...settings} ref={slider} className={styles["cards-list"]}>
        {vals.map((val, index) => {
          return <Card val={val} key={index} path={path} />;
        })}
      </Slider>
    </div>
  );
};

export default Cards;
