import React, { useState, useEffect } from "react";
import { rest } from "@karpeleslab/klbfw";
import { useTranslation } from "react-i18next";
import Toast, { error, success } from "components/Toast/Toast";

// component
import Button from "components/Btns/Btn";

// styles
import styles from "../../MyPage.module.scss";

const DisplayBilling = ({ billing, billingRefresh, setEdit }) => {
  const { t } = useTranslation();
  const [billingMethod, setBillingMethod] = useState(null);

  useEffect(() => {
    if (billing.data.length !== 0) {
      rest("User/Billing/" + billing.data[0].User_Billing__ + "/Method").then(
        (d) => {
          setBillingMethod(d.data[0]);
        }
      );
    }
  }, [billing]);

  const deleteHandler = () => {
    rest(`User/Billing/${billing.data[0].User_Billing__}`, "DELETE")
      .then(() => {
        success("toast_delete_success_text", true, {
          position: "top-center",
          autoClose: 1500,
          onClose: () => {
            billingRefresh();
          },
        });
      })
      .catch((err) => {
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  if (billingMethod === null) return false;

  return (
    <div>
      <div className={styles["mypage-billing-display"]}>
        <p>{t("mypage_payment_method_name", { name: billingMethod.Name })}</p>
        <p>
          {t("mypage_payment_method_expiration", {
            expiration: billingMethod.Expiration,
          })}
        </p>
      </div>
      <div
        className={`${styles["mypage-btns-group"]} ${styles["mypage-btns-group__center"]}`}
      >
        <Button
          iconPosition="noting"
          onClick={() => {
            setEdit(true);
          }}
        >
          {t("mypage_payment_edit")}
        </Button>
        <Button iconPosition="noting" type="danger" onClick={deleteHandler}>
          {t("mypage_payment_remove")}
        </Button>
      </div>
      <Toast duration={5000} />
    </div>
  );
};

export default DisplayBilling;
