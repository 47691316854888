import React, { useState } from "react";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { useRest, useRestRefresh } from "@karpeleslab/react-klbfw-hooks";
import { rest, flushGet } from "@karpeleslab/klbfw";
import { Link } from "react-router-dom";
import ScrollLock, { TouchScrollable } from "react-scrolllock";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

// style
import styles from "./Header.module.scss";

// img
import Logo from "assets/img/logo.png";
import iconBarger from "assets/img/icon-burger.svg";
import iconClose from "assets/img/icon-close.png";

const Header = () => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const [menuShow, setMenuShow] = useState(false);
  const [user] = useRest("User:get");
  const userRefresh = useRestRefresh("User:get");
  const [cart] = useRest("Catalog/Cart/@");

  const logOut = () => {
    rest("User/@:logout", "POST").then(() => {
      flushGet();
      userRefresh();
      hideMenu();
      history.push("/logout");
    });
  };

  const hideMenu = () => {
    setMenuShow(false);
  };

  return (
    <>
      <header className={styles["header"]}>
        {location.pathname === "/" ? (
          <h1 className={styles["header-title"]}>
            <Link to="/">
              <img src={Logo} alt={t("common_ncih")} />
            </Link>
          </h1>
        ) : (
          <p className={styles["header-title"]}>
            <Link to="/">
              <img src={Logo} alt={t("common_ncih")} />
            </Link>
          </p>
        )}
        <ul className={styles["header-list"]}>
          <li className={styles["header-list-item"]}>
            <Link to="/ncih">{t("common_ncih")}</Link>
          </li>
          <li className={styles["header-list-item"]}>
            <Link to="/information">{t("common_info")}</Link>
          </li>
          <li className={styles["header-list-item"]}>
            <Link to="/member">{t("common_support_member")}</Link>
          </li>
          <li className={styles["header-list-item"]}>
            <Link to="/ihic">{t("common_ihic")}</Link>
          </li>
          <li
            className={`${styles["header-list-item"]} ${styles["header-list-item-btn"]}`}
            onClick={() => setMenuShow(!menuShow)}
          >
            <img src={iconBarger} />
          </li>
        </ul>
      </header>
      <TouchScrollable>
        <div
          className={clsx(styles["header-overlay"], {
            [styles["header-overlay-active"]]: menuShow,
          })}
        >
          <div className={styles["header-overlay-inner"]}>
            <ul className={styles["header-overlay-list"]}>
              <li className={styles["header-overlay-list-item"]}>
                <Link onClick={() => hideMenu()} to="/ncih">
                  {t("common_ncih_about")}
                </Link>
                <ul className={styles["header-overlay-list-item-list"]}>
                  <li className={styles["header-overlay-list-item-list-item"]}>
                    <Link onClick={() => hideMenu()} to="/ncih/founder">
                      {t("common_founder")}
                    </Link>
                  </li>
                  <li className={styles["header-overlay-list-item-list-item"]}>
                    <Link onClick={() => hideMenu()} to="/ncih/organizational">
                      {t("common_organizational")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className={styles["header-overlay-list-item"]}>
                <Link onClick={() => hideMenu()} to="/member">
                  {t("common_support_member_recruitment")}
                </Link>
                {/* <ul className={styles["header-overlay-list-item-list"]}>
                  <li className={styles["header-overlay-list-item-list-item"]}>
                    <Link onClick={() => hideMenu()} to="/member/list">
                      {t("common_support_member_list")}
                    </Link>
                  </li>
                </ul> */}
              </li>
            </ul>
            <ul className={styles["header-overlay-list"]}>
              <li className={styles["header-overlay-list-item"]}>
                <Link onClick={() => hideMenu()} to="/ihic">
                  {t("common_ihic")}
                </Link>
              </li>
              <li className={styles["header-overlay-list-item"]}>
                <Link onClick={() => hideMenu()} to="/information">
                  {t("common_info")}
                </Link>
              </li>
              <li className={styles["header-overlay-list-item"]}>
                <Link onClick={() => hideMenu()} to="/news">
                  {t("common_news")}
                </Link>
              </li>
              <li className={styles["header-overlay-list-item"]}>
                <Link onClick={() => hideMenu()} to="/world">
                  {t("common_world")}
                </Link>
              </li>
              <li className={styles["header-overlay-list-item"]}>
                <Link onClick={() => hideMenu()} to="/support">
                  {t("common_support")}
                </Link>
              </li>
              <li className={styles["header-overlay-list-item"]}>
                <Link onClick={() => hideMenu()} to="/archives">
                  {t("common_archives")}
                </Link>
              </li>
            </ul>
            <ul className={styles["header-overlay-list"]}>
              <li className={styles["header-overlay-list-item"]}>
                <Link onClick={() => hideMenu()} to="/page/tos">
                  {t("common_terms_of_use")}
                </Link>
              </li>
              <li className={styles["header-overlay-list-item"]}>
                <Link onClick={() => hideMenu()} to="/page/scta">
                  {t("common_scta")}
                </Link>
              </li>
              <li className={styles["header-overlay-list-item"]}>
                <Link onClick={() => hideMenu()} to="/page/privacy">
                  {t("common_privacy")}
                </Link>
              </li>
              <li
                className={`${styles["header-overlay-list-item"]} ${styles["header-overlay-list-item-cart"]}`}
              >
                <Link onClick={() => hideMenu()} to="/cart">
                  {t("common_cart")}
                  {cart !== null && (
                    <span className={styles["header-overlay-list-item-count"]}>
                      {cart.data.products.length}
                    </span>
                  )}
                </Link>
              </li>
              {user?.data !== null && (
                <>
                  <li className={styles["header-overlay-list-item"]}>
                    <Link onClick={() => logOut()} to="/login">
                      {t("common_logout")}
                    </Link>
                  </li>
                  <li className={styles["header-overlay-list-item"]}>
                    <Link onClick={() => hideMenu()} to="/mypage">
                      {t("common_mypage")}
                    </Link>
                  </li>
                </>
              )}

              {user?.data === null && (
                <li className={styles["header-overlay-list-item"]}>
                  <Link onClick={() => hideMenu()} to="/login">
                    {t("common_login")}
                  </Link>
                </li>
              )}
            </ul>
          </div>
          <button
            onClick={() => setMenuShow(false)}
            className={styles["header-overlay-btn"]}
          >
            <img src={iconClose} alt="" />
          </button>
        </div>
      </TouchScrollable>
      <ScrollLock isActive={menuShow} />
    </>
  );
};

export default Header;
