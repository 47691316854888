import React from "react";

// style
import styles from "./List.module.scss";

const List = ({ text = [] }) => {
  return (
    <ul className={styles["list"]}>
      {text.map((val, index) => {
        return (
          <li className={styles["list-item"]} key={index}>
            {val}
          </li>
        );
      })}
    </ul>
  );
};

export default List;
