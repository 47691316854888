import React from "react";
import { useTranslation } from "react-i18next";

//material ui
import { Box } from "@material-ui/core";

// img
import LgImg from "assets/img/img-about-liquidGalaxy.jpg";
import PannelImg from "assets/img/img-about-panel.jpg";
import TabletImg from "assets/img/img-about-tablet.jpg";

// style
import styles from "../Ihic.module.scss";

const Exhibition = () => {
  const { t } = useTranslation();

  return (
    <Box className={`${styles["page-container"]}`}>
      <ul className={styles["ihic-list"]}>
        <li className={styles["ihic-list-item"]}>
          <img
            onContextMenu={(e) => {
              e.preventDefault();
              e.stopPropagation();
              return false;
            }}
            src={LgImg}
            alt="p-about-body__img"
          />
          <span className={styles["ihic-list-item-text"]}>
            {t("about_img_lg")}
          </span>
        </li>
        <li className={styles["ihic-list-item"]}>
          <img
            onContextMenu={(e) => {
              e.preventDefault();
              e.stopPropagation();
              return false;
            }}
            src={PannelImg}
            alt="p-about-body__img"
          />
          <span className={styles["ihic-list-item-text"]}>
            {t("about_img_pannel")}
          </span>
        </li>
        <li className={styles["ihic-list-item"]}>
          <img
            onContextMenu={(e) => {
              e.preventDefault();
              e.stopPropagation();
              return false;
            }}
            src={TabletImg}
            alt="p-about-body__img"
          />
          <span className={styles["ihic-list-item-text"]}>
            {t("about_img_tablet")}
          </span>
        </li>
      </ul>
    </Box>
  );
};

export default Exhibition;
