import React from "react";
import { useTranslation } from "react-i18next";
import { useWindowWidth } from "@react-hook/window-size";

//material ui
import { Grid } from "@material-ui/core";

// components
import BtnGroup from "components/Btns/BtnGroup";

// img
import Bg from "assets/img/bg-index-hero.png";
import heroTitle from "assets/img/img-index-hero-title.png";
import heroTitleSp from "assets/img/img-index-hero-title-sp.png";

// style
import styles from "./Hero.module.scss";

const Hero = () => {
  const { t } = useTranslation();
  const onlyWidth = useWindowWidth();
  return (
    <div className={styles["hero-container"]}>
      <div
        className={styles["hero"]}
        style={{
          backgroundImage: `url(${Bg})`,
        }}
      >
        <div className={styles["hero-info"]}>
          {onlyWidth <= 959 ? (
            <img
              src={heroTitleSp}
              alt={`${t("common_ncih")} ${t("common_catch_copy")}`}
            />
          ) : (
            <img
              src={heroTitle}
              alt={`${t("common_ncih")} ${t("common_catch_copy")}`}
            />
          )}
          <p className={styles["hero-text"]}>
            産業を支えた人々の人生。歴史の教科書に登場してこない人々の人生。
            <br />
            彼らの汗で築かれた生活文化や知恵こそが、本当の意味での「産業遺産」である。
            <br />
            笑い、涙、怒り、わたしたちは人生を風化させたくない。
          </p>
        </div>
      </div>
      <Grid container item justify="center">
        <BtnGroup
          btns={[
            {
              text: t("common_ncih_about"),
              to: "/ncih",
            },
            {
              text: t("common_btn_way"),
              to: "http://www.japansmeijiindustrialrevolution.com/history",
            },
          ]}
        />
      </Grid>
    </div>
  );
};

export default Hero;
