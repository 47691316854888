export const IMG_VARIATION = "format=jpeg&strip&scale_crop=374x247";
export const IMG_VARIATION_INFOMATION = "format=jpeg&strip&scale_crop=400x300";

// detail
export const IMG_VARIATION_INFOMATION_DETAIL =
  "format=jpeg&strip&scale_crop=680x510";

export const NEWS_PAGE_COUNT_TOP = 4;
export const NEWS_PAGE_COUNT = 10;
export const NEWS_PAGE_COUNT_TEST = 1;

export const INFORMATION_PAGE_COUNT_TOP = 10;
export const INFORMATION_PAGE_COUNT = 15;
export const INFORMATION_PAGE_COUNT_TEST = 1;

export const HISTORY_PAGE_COUNT = 10;

export const BILLING_LABEL = "ncih payment method";

export const STRIPE_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      iconColor: "#737475",
      color: "#737475",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#737475" },
      "::placeholder": { color: "#737475" },
    },
    invalid: {
      iconColor: "#ff0000",
      color: "#ff0000",
    },
  },
};

export const CATALOG_PRODUCT = "cpr-46i6mk-ukxf-h7xp-jqpt-h6sn55ci";
