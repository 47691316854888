import React from "react";
import { Switch, Route } from "react-router-dom";

// Material Ui
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { Theme } from "assets/jss/Theme";
import { StylesProvider } from "@material-ui/core/styles";

// layout
import Layout from "components/Layout/Layout";

// page
import Index from "views/Index/Index";
import Ncih from "views/Ncih/Ncih";
import Founder from "views/Ncih/Founder";
import Organizational from "views/Ncih/Organizational";
import Ihic from "views/Ihic/Ihic";
import Member from "views/Member/Member";
import Archives from "views/Archives/Archives";
import World from "views/World/World";
import Privacy from "views/Privacy/Privacy";
import Support from "views/Support/Support";
import Information from "views/Information/Information";
import InformationDetail from "views/Information/InformationDetail";
import News from "views/News/News";
import Page from "views/Page/Page";
import Login from "views/Login/Login";
import Logout from "views/Logout/Logout";
import MyPage from "views/MyPage/MyPage";
import Cart from "views/Cart/Cart";
import Checkout from "views/Checkout/Checkout";
import Order from "views/Order/Order";
import NotFound from "views/404/404";
import RedirectPage from "views/Redirect/Redirect";

// util
import LoginRequired from "utils/LoginRequired";

// mail

const Mail = () => {
  return <Switch></Switch>;
};

const Pages = () => {
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <StylesProvider injectFirst>
        <Layout>
          <Switch>
            <Route exact path="/">
              <Index />
            </Route>
            <Route exact path="/ihic">
              <Ihic />
            </Route>
            <Route exact path="/ncih">
              <Ncih />
            </Route>
            <Route exact path="/ncih/founder">
              <Founder />
            </Route>
            <Route exact path="/ncih/organizational">
              <Organizational />
            </Route>
            <Route exact path="/member">
              <Member />
            </Route>
            {/* <Route exact path="/member/list">
              <MemberList />
            </Route> */}
            <Route exact path="/archives">
              <Archives />
            </Route>
            <Route exact path="/world">
              <World />
            </Route>
            <Route exact path="/privacy">
              <Privacy />
            </Route>
            <Route exact path="/support">
              <Support />
            </Route>
            <Route exact path="/information">
              <Information />
            </Route>
            <Route exact path="/information/page-:pageNum">
              <Information />
            </Route>
            <Route exact path="/information/tag-:tag">
              <Information />
            </Route>
            <Route exact path="/information/tag-:tag/page-:pageNum">
              <Information />
            </Route>
            <Route exact path="/information/:slug">
              <InformationDetail />
            </Route>
            <Route exact path="/news">
              <News />
            </Route>
            <Route exact path="/news/:yearNum/page-:pageNum">
              <News />
            </Route>
            <Route exact path="/page/:slug">
              <Page />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/logout">
              <Logout />
            </Route>
            <Route exact path="/mypage">
              <LoginRequired>
                <MyPage type="history" />
              </LoginRequired>
            </Route>
            <Route exact path="/mypage/page-:pageNum">
              <LoginRequired>
                <MyPage type="history" />
              </LoginRequired>
            </Route>
            <Route exact path="/mypage/user">
              <LoginRequired>
                <MyPage type="user" />
              </LoginRequired>
            </Route>
            <Route exact path="/mypage/profile">
              <LoginRequired>
                <MyPage type="profile" />
              </LoginRequired>
            </Route>
            <Route exact path="/mypage/password">
              <LoginRequired>
                <MyPage type="password" />
              </LoginRequired>
            </Route>
            <Route exact path="/mypage/billing">
              <LoginRequired>
                <MyPage type="billing" />
              </LoginRequired>
            </Route>
            <Route exact path="/cart">
              <Cart />
            </Route>
            <Route exact path="/checkout">
              <LoginRequired>
                <Checkout />
              </LoginRequired>
            </Route>
            <Route exact path="/order/:order">
              <LoginRequired>
                <Order />
              </LoginRequired>
            </Route>
            <Route exact path="/redirect">
              <RedirectPage />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </Layout>
      </StylesProvider>
    </ThemeProvider>
  );
};

const Core = () => {
  return (
    <Switch>
      <Route path="/mail">
        <Mail />
      </Route>
      <Route>
        <Pages />
      </Route>
    </Switch>
  );
};

const Routing = () => {
  return <Core />;
};

export default Routing;
