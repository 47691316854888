import React from "react";
import { useTranslation } from "react-i18next";

// component
import InnerLayout from "components/Layout/InnerLayout";
import PageHeader from "components/PageHeader/PageHeader";
import Helmet from "components/Helmet/Helmet";

// style
import styles from "./Ncih.module.scss";

const Ncih = () => {
  const { t } = useTranslation();

  const group = [
    {
      title: t("ncih_organizational_table_title_1"),
      name: t("ncih_organizational_table_name_1"),
      company: t("ncih_organizational_table_company_1"),
    },
    {
      title: t("ncih_organizational_table_title_2"),
      name: t("ncih_organizational_table_name_2"),
      company: t("ncih_organizational_table_company_2"),
    },
    {
      title: t("ncih_organizational_table_title_3"),
      name: t("ncih_organizational_table_name_3"),
      company: t("ncih_organizational_table_company_3"),
    },
    {
      title: t("ncih_organizational_table_title_4"),
      name: t("ncih_organizational_table_name_4"),
      company: t("ncih_organizational_table_company_4"),
    },
    {
      title: t("ncih_organizational_table_title_5"),
      name: t("ncih_organizational_table_name_5"),
      company: t("ncih_organizational_table_company_5"),
    },
    {
      title: t("ncih_organizational_table_title_6"),
      name: t("ncih_organizational_table_name_6"),
      company: t("ncih_organizational_table_company_6"),
    },
    {
      title: t("ncih_organizational_table_title_7"),
      name: t("ncih_organizational_table_name_7"),
      company: t("ncih_organizational_table_company_7"),
    },
    {
      title: t("ncih_organizational_table_title_8"),
      name: t("ncih_organizational_table_name_8"),
      company: t("ncih_organizational_table_company_8"),
    },
    {
      title: t("ncih_organizational_table_title_9"),
      name: t("ncih_organizational_table_name_9"),
      company: t("ncih_organizational_table_company_9"),
    },
    {
      title: t("ncih_organizational_table_title_10"),
      name: t("ncih_organizational_table_name_10"),
      company: t("ncih_organizational_table_company_10"),
    },
    {
      title: t("ncih_organizational_table_title_11"),
      name: t("ncih_organizational_table_name_11"),
      company: t("ncih_organizational_table_company_11"),
    },
    {
      title: t("ncih_organizational_table_title_12"),
      name: t("ncih_organizational_table_name_12"),
      company: t("ncih_organizational_table_company_12"),
    },
    {
      title: t("ncih_organizational_table_title_13"),
      name: t("ncih_organizational_table_name_13"),
      company: t("ncih_organizational_table_company_13"),
    },
    {
      title: t("ncih_organizational_table_title_14"),
      name: t("ncih_organizational_table_name_14"),
      company: t("ncih_organizational_table_company_14"),
    },
    {
      title: t("ncih_organizational_table_title_15"),
      name: t("ncih_organizational_table_name_15"),
      company: t("ncih_organizational_table_company_15"),
    },
    {
      title: t("ncih_organizational_table_title_16"),
      name: t("ncih_organizational_table_name_16"),
      company: t("ncih_organizational_table_company_16"),
    },
    {
      title: t("ncih_organizational_table_title_26"),
      name: t("ncih_organizational_table_name_26"),
      company: t("ncih_organizational_table_company_26"),
    },
    {
      title: t("ncih_organizational_table_title_17"),
      name: t("ncih_organizational_table_name_17"),
      company: t("ncih_organizational_table_company_17"),
    },
    {
      title: t("ncih_organizational_table_title_18"),
      name: t("ncih_organizational_table_name_18"),
      company: t("ncih_organizational_table_company_18"),
    },
    {
      title: t("ncih_organizational_table_title_19"),
      name: t("ncih_organizational_table_name_19"),
      company: t("ncih_organizational_table_company_19"),
    },
    {
      title: t("ncih_organizational_table_title_20"),
      name: t("ncih_organizational_table_name_20"),
      company: t("ncih_organizational_table_company_20"),
    },
    {
      title: t("ncih_organizational_table_title_21"),
      name: t("ncih_organizational_table_name_21"),
      company: t("ncih_organizational_table_company_21"),
    },
    {
      title: t("ncih_organizational_table_title_22"),
      name: t("ncih_organizational_table_name_22"),
      company: t("ncih_organizational_table_company_22"),
    },
    {
      title: t("ncih_organizational_table_title_23"),
      name: t("ncih_organizational_table_name_23"),
      company: t("ncih_organizational_table_company_23"),
    },
    {
      title: t("ncih_organizational_table_title_24"),
      name: t("ncih_organizational_table_name_24"),
      company: t("ncih_organizational_table_company_24"),
    },
    {
      title: t("ncih_organizational_table_title_25"),
      name: t("ncih_organizational_table_name_25"),
      company: t("ncih_organizational_table_company_25"),
    },
  ];

  return (
    <InnerLayout
      breadcrumb={[
        {
          to: "/ncih",
          text: t("ncih_sub_title"),
        },
        {
          to: "/ncih/organizational",
          text: t("ncih_organizational_sub_title"),
        },
      ]}
    >
      <Helmet>
        <title>{`${t("ncih_organizational_sub_title")} - ${t(
          "common_ncih"
        )}`}</title>
        <meta
          property="og:title"
          content={`${t("ncih_organizational_sub_title")} - ${t(
            "common_ncih"
          )}`}
        />
        <meta
          property="og:site_name"
          content={`${t("ncih_organizational_sub_title")} - ${t(
            "common_ncih"
          )}`}
        />
      </Helmet>
      <PageHeader
        title={t("ncih_organizational_title")}
        subTitle={t("ncih_organizational_sub_title")}
      />
      <div
        className={`${styles["page-container"]} ${styles["organizational-container"]}`}
      >
        <h2 className={styles["organizational-title"]}>
          {t("ncih_organizational_page_title")}
        </h2>
        <p className={styles["organizational-sub-title"]}>
          {t("ncih_organizational_page_date")}
        </p>
        <div className={styles["organizational"]}>
          <table className={styles["organizational-table"]}>
            <tbody>
              {group.map((val, index) => {
                return (
                  <tr key={index}>
                    <td className={styles["organizational-table-title"]}>
                      {val.title}
                    </td>
                    <td className={styles["organizational-table-name"]}>
                      {val.name}
                    </td>
                    <td className={styles["organizational-table-company"]}>
                      {val.company}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <p className={styles["organizational-table-date"]}>
            {t("ncih_organizational_page_date_2")}
          </p>
        </div>
      </div>
    </InnerLayout>
  );
};

export default Ncih;
