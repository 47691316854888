import React from "react";
import { useTranslation } from "react-i18next";

// component
import InnerLayout from "components/Layout/InnerLayout";
import PageHeader from "components/PageHeader/PageHeader";
import Helmet from "components/Helmet/Helmet";
import About from "./section/About";
import Exhibition from "./section/Exhibition";
import Greeting from "./section/Greeting";
import Content from "./section/Content";
import ContactInfoIhic from "components/ContactInfo/ContactInfoIhic";

// img
import PageHeaderImg from "assets/img/img-page-header-ihic.png";

const Ncih = () => {
  const { t } = useTranslation();

  return (
    <InnerLayout
      breadcrumb={[
        {
          to: "",
          text: t("ihic_sub_title"),
        },
      ]}
    >
      <Helmet>
        <title>{`${t("ihic_sub_title")} - ${t("common_ncih")}`}</title>
        <meta
          property="og:title"
          content={`${t("ihic_sub_title")} - ${t("common_ncih")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("ihic_sub_title")} - ${t("common_ncih")}`}
        />
      </Helmet>
      <PageHeader
        title={t("ihic_title")}
        subTitle={t("ihic_sub_title")}
        bgImg={PageHeaderImg}
      />
      <About />
      <Exhibition />
      <Greeting />
      <Content />
      <ContactInfoIhic />
    </InnerLayout>
  );
};

export default Ncih;
