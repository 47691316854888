import React from "react";
import { useTranslation } from "react-i18next";

// component
import InnerLayout from "components/Layout/InnerLayout";
import PageHeader from "components/PageHeader/PageHeader";
import Helmet from "components/Helmet/Helmet";

// style
import styles from "./Ncih.module.scss";

const Ncih = () => {
  const { t } = useTranslation();

  const group = {
    group1: [
      {
        name: t("ncih_founder_table_group1_name_1"),
        title: t("ncih_founder_table_group1_title_1"),
      },
      {
        name: t("ncih_founder_table_group1_name_2"),
        title: t("ncih_founder_table_group1_title_2"),
      },
      {
        name: t("ncih_founder_table_group1_name_3"),
        title: t("ncih_founder_table_group1_title_3"),
      },
      {
        name: t("ncih_founder_table_group1_name_4"),
        title: t("ncih_founder_table_group1_title_4"),
      },
      {
        name: t("ncih_founder_table_group1_name_5"),
        title: t("ncih_founder_table_group1_title_5"),
      },
      {
        name: t("ncih_founder_table_group1_name_6"),
        title: t("ncih_founder_table_group1_title_6"),
      },
      {
        name: t("ncih_founder_table_group1_name_7"),
        title: t("ncih_founder_table_group1_title_7"),
      },
      {
        name: t("ncih_founder_table_group1_name_8"),
        title: t("ncih_founder_table_group1_title_8"),
      },
      {
        name: t("ncih_founder_table_group1_name_9"),
        title: t("ncih_founder_table_group1_title_9"),
      },
      {
        name: t("ncih_founder_table_group1_name_10"),
        title: t("ncih_founder_table_group1_title_10"),
      },
      {
        name: t("ncih_founder_table_group1_name_11"),
        title: t("ncih_founder_table_group1_title_11"),
      },
      {
        name: t("ncih_founder_table_group1_name_12"),
        title: t("ncih_founder_table_group1_title_12"),
      },
      {
        name: t("ncih_founder_table_group1_name_13"),
        title: t("ncih_founder_table_group1_title_13"),
      },
      {
        name: t("ncih_founder_table_group1_name_14"),
        title: t("ncih_founder_table_group1_title_14"),
      },
      {
        name: t("ncih_founder_table_group1_name_15"),
        title: t("ncih_founder_table_group1_title_15"),
      },
      {
        name: t("ncih_founder_table_group1_name_16"),
        title: t("ncih_founder_table_group1_title_16"),
      },
      {
        name: t("ncih_founder_table_group1_name_17"),
        title: t("ncih_founder_table_group1_title_17"),
      },
    ],
    group2: [
      {
        name: t("ncih_founder_table_group2_name_1"),
        title: t("ncih_founder_table_group2_title_1"),
      },
      {
        name: t("ncih_founder_table_group2_name_2"),
        title: t("ncih_founder_table_group2_title_2"),
      },
      {
        name: t("ncih_founder_table_group2_name_3"),
        title: t("ncih_founder_table_group2_title_3"),
      },
      {
        name: t("ncih_founder_table_group2_name_4"),
        title: t("ncih_founder_table_group2_title_4"),
      },
      {
        name: t("ncih_founder_table_group2_name_5"),
        title: t("ncih_founder_table_group2_title_5"),
      },
      {
        name: t("ncih_founder_table_group2_name_6"),
        title: t("ncih_founder_table_group2_title_6"),
      },
      {
        name: t("ncih_founder_table_group2_name_7"),
        title: t("ncih_founder_table_group2_title_7"),
      },
      {
        name: t("ncih_founder_table_group2_name_8"),
        title: t("ncih_founder_table_group2_title_8"),
      },
      {
        name: t("ncih_founder_table_group2_name_9"),
        title: t("ncih_founder_table_group2_title_9"),
      },
      {
        name: t("ncih_founder_table_group2_name_10"),
        title: t("ncih_founder_table_group2_title_10"),
      },
      {
        name: t("ncih_founder_table_group2_name_11"),
        title: t("ncih_founder_table_group2_title_11"),
      },
      {
        name: t("ncih_founder_table_group2_name_12"),
        title: t("ncih_founder_table_group2_title_12"),
      },
      {
        name: t("ncih_founder_table_group2_name_13"),
        title: t("ncih_founder_table_group2_title_13"),
      },
    ],
    group3: [
      {
        name: t("ncih_founder_table_group3_name_1"),
        title: t("ncih_founder_table_group3_title_1"),
      },
      {
        name: t("ncih_founder_table_group3_name_2"),
        title: t("ncih_founder_table_group3_title_2"),
      },
      {
        name: t("ncih_founder_table_group3_name_3"),
        title: t("ncih_founder_table_group3_title_3"),
      },
    ],
    group4: [
      {
        name: t("ncih_founder_table_group4_name_1"),
        title: t("ncih_founder_table_group4_title_1"),
      },
      {
        name: t("ncih_founder_table_group4_name_2"),
        title: t("ncih_founder_table_group4_title_2"),
      },
      {
        name: t("ncih_founder_table_group4_name_3"),
        title: t("ncih_founder_table_group4_title_3"),
      },
      {
        name: t("ncih_founder_table_group4_name_4"),
        title: t("ncih_founder_table_group4_title_4"),
      },
    ],
    group5: [
      {
        name: t("ncih_founder_table_group5_name_1"),
        title: t("ncih_founder_table_group5_title_1"),
      },
    ],
    group6: [
      {
        name: t("ncih_founder_table_group6_name_1"),
        title: t("ncih_founder_table_group6_title_1"),
      },
      {
        name: t("ncih_founder_table_group6_name_2"),
        title: t("ncih_founder_table_group6_title_2"),
      },
      {
        name: t("ncih_founder_table_group6_name_3"),
        title: t("ncih_founder_table_group6_title_3"),
      },
      {
        name: t("ncih_founder_table_group6_name_4"),
        title: t("ncih_founder_table_group6_title_4"),
      },
    ],
    group7: [
      {
        name: t("ncih_founder_table_group7_name_1"),
        title: t("ncih_founder_table_group7_title_1"),
      },
      {
        name: t("ncih_founder_table_group7_name_2"),
        title: t("ncih_founder_table_group7_title_2"),
      },
      {
        name: t("ncih_founder_table_group7_name_3"),
        title: t("ncih_founder_table_group7_title_3"),
      },
      {
        name: t("ncih_founder_table_group7_name_4"),
        title: t("ncih_founder_table_group7_title_4"),
      },
      {
        name: t("ncih_founder_table_group7_name_5"),
        title: t("ncih_founder_table_group7_title_5"),
      },
      {
        name: t("ncih_founder_table_group7_name_6"),
        title: t("ncih_founder_table_group7_title_6"),
      },
      {
        name: t("ncih_founder_table_group7_name_7"),
        title: t("ncih_founder_table_group7_title_7"),
      },
      {
        name: t("ncih_founder_table_group7_name_8"),
        title: t("ncih_founder_table_group7_title_8"),
      },
      {
        name: t("ncih_founder_table_group7_name_9"),
        title: t("ncih_founder_table_group7_title_9"),
      },
      {
        name: t("ncih_founder_table_group7_name_10"),
        title: t("ncih_founder_table_group7_title_10"),
      },
      {
        name: t("ncih_founder_table_group7_name_11"),
        title: t("ncih_founder_table_group7_title_11"),
      },
      {
        name: t("ncih_founder_table_group7_name_12"),
        title: t("ncih_founder_table_group7_title_12"),
      },
      {
        name: t("ncih_founder_table_group7_name_13"),
        title: t("ncih_founder_table_group7_title_13"),
      },
      {
        name: t("ncih_founder_table_group7_name_14"),
        title: t("ncih_founder_table_group7_title_14"),
      },
      {
        name: t("ncih_founder_table_group7_name_15"),
        title: t("ncih_founder_table_group7_title_15"),
      },
      {
        name: t("ncih_founder_table_group7_name_16"),
        title: t("ncih_founder_table_group7_title_16"),
      },
      {
        name: t("ncih_founder_table_group7_name_17"),
        title: t("ncih_founder_table_group7_title_17"),
      },
      {
        name: t("ncih_founder_table_group7_name_18"),
        title: t("ncih_founder_table_group7_title_18"),
      },
      {
        name: t("ncih_founder_table_group7_name_19"),
        title: t("ncih_founder_table_group7_title_19"),
      },
      {
        name: t("ncih_founder_table_group7_name_20"),
        title: t("ncih_founder_table_group7_title_20"),
      },
    ],
    group8: [
      {
        name: t("ncih_founder_table_group8_name_1"),
        title: t("ncih_founder_table_group8_title_1"),
      },
      {
        name: t("ncih_founder_table_group8_name_2"),
        title: t("ncih_founder_table_group8_title_2"),
      },
      {
        name: t("ncih_founder_table_group8_name_3"),
        title: t("ncih_founder_table_group8_title_3"),
      },
    ],
    group9: [
      {
        name: t("ncih_founder_table_group9_name_1"),
        title: t("ncih_founder_table_group9_title_1"),
      },
      {
        name: t("ncih_founder_table_group9_name_2"),
        title: t("ncih_founder_table_group9_title_2"),
      },
    ],
    group10: [
      {
        name: t("ncih_founder_table_group10_name_1"),
        title: t("ncih_founder_table_group10_title_1"),
      },
      {
        name: t("ncih_founder_table_group10_name_2"),
        title: t("ncih_founder_table_group10_title_2"),
      },
      {
        name: t("ncih_founder_table_group10_name_3"),
        title: t("ncih_founder_table_group10_title_3"),
      },
      {
        name: t("ncih_founder_table_group10_name_4"),
        title: t("ncih_founder_table_group10_title_4"),
      },
      {
        name: t("ncih_founder_table_group10_name_5"),
        title: t("ncih_founder_table_group10_title_5"),
      },
      {
        name: t("ncih_founder_table_group10_name_6"),
        title: t("ncih_founder_table_group10_title_6"),
      },
      {
        name: t("ncih_founder_table_group10_name_7"),
        title: t("ncih_founder_table_group10_title_7"),
      },
      {
        name: t("ncih_founder_table_group10_name_8"),
        title: t("ncih_founder_table_group10_title_8"),
      },
    ],
    group11: [
      {
        name: t("ncih_founder_table_group11_name_1"),
        title: t("ncih_founder_table_group11_title_1"),
      },
      {
        name: t("ncih_founder_table_group11_name_2"),
        title: t("ncih_founder_table_group11_title_2"),
      },
    ],
    group12: [
      {
        name: t("ncih_founder_table_group12_name_1"),
        title: t("ncih_founder_table_group12_title_1"),
      },
      {
        name: t("ncih_founder_table_group12_name_2"),
        title: t("ncih_founder_table_group12_title_2"),
      },
      {
        name: t("ncih_founder_table_group12_name_3"),
        title: t("ncih_founder_table_group12_title_3"),
      },
      {
        name: t("ncih_founder_table_group12_name_4"),
        title: t("ncih_founder_table_group12_title_4"),
      },
    ],
    group13: [
      {
        name: t("ncih_founder_table_group13_name_1"),
        title: t("ncih_founder_table_group13_title_1"),
      },
    ],
    group14: [
      {
        name: t("ncih_founder_table_group14_name_1"),
        title: t("ncih_founder_table_group14_title_1"),
      },
      {
        name: t("ncih_founder_table_group14_name_2"),
        title: t("ncih_founder_table_group14_title_2"),
      },
    ],
    group15: [
      {
        name: t("ncih_founder_table_group15_name_1"),
        title: t("ncih_founder_table_group15_title_1"),
      },
    ],
  };

  return (
    <InnerLayout
      breadcrumb={[
        {
          to: "/ncih",
          text: t("ncih_sub_title"),
        },
        {
          to: "/ncih/founder",
          text: t("ncih_founder_sub_title"),
        },
      ]}
    >
      <Helmet>
        <title>{`${t("ncih_founder_sub_title")} - ${t("common_ncih")}`}</title>
        <meta
          property="og:title"
          content={`${t("ncih_founder_sub_title")} - ${t("common_ncih")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("ncih_founder_sub_title")} - ${t("common_ncih")}`}
        />
      </Helmet>
      <PageHeader
        title={t("ncih_founder_title")}
        subTitle={t("ncih_founder_sub_title")}
      />
      <div
        className={`${styles["page-container"]} ${styles["founder-container"]}`}
      >
        <h2 className={styles["founder-title"]}>
          {t("ncih_founder_table_title")}
        </h2>
        <div className={styles["founder"]}>
          <table className={styles["founder-table"]}>
            <tbody>
              {group.group1.map((val, index) => {
                return (
                  <tr key={index}>
                    <td className={styles["founder-table-name"]}>{val.name}</td>
                    <td className={styles["founder-table-title"]}>
                      {val.title}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <p className={styles["founder-date"]}>
            {t("ncih_founder_table_date_1")}
          </p>
        </div>
      </div>
      <div
        className={`${styles["page-container"]} ${styles["founder-container"]}`}
      >
        <h2 className={styles["founder-title"]}>
          {t("ncih_founder_table_title")}
        </h2>
        <h3>{t("ncih_founder_table_sub_title_1")}</h3>
        <div className={styles["founder"]}>
          <table className={styles["founder-table"]}>
            <tbody>
              {group.group2.map((val, index) => {
                return (
                  <tr key={index}>
                    <td className={styles["founder-table-name"]}>{val.name}</td>
                    <td className={styles["founder-table-title"]}>
                      {val.title}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <h3>{t("ncih_founder_table_sub_title_2")}</h3>
        <div className={styles["founder"]}>
          <table className={styles["founder-table"]}>
            <tbody>
              {group.group3.map((val, index) => {
                return (
                  <tr key={index}>
                    <td className={styles["founder-table-name"]}>{val.name}</td>
                    <td className={styles["founder-table-title"]}>
                      {val.title}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <h3>{t("ncih_founder_table_sub_title_3")}</h3>
        <div className={styles["founder"]}>
          <table className={styles["founder-table"]}>
            <tbody>
              {group.group4.map((val, index) => {
                return (
                  <tr key={index}>
                    <td className={styles["founder-table-name"]}>{val.name}</td>
                    <td className={styles["founder-table-title"]}>
                      {val.title}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <h3>{t("ncih_founder_table_sub_title_4")}</h3>
        <div className={styles["founder"]}>
          <table className={styles["founder-table"]}>
            <tbody>
              {group.group5.map((val, index) => {
                return (
                  <tr key={index}>
                    <td className={styles["founder-table-name"]}>{val.name}</td>
                    <td className={styles["founder-table-title"]}>
                      {val.title}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <h3>{t("ncih_founder_table_sub_title_5")}</h3>
        <div className={styles["founder"]}>
          <table className={styles["founder-table"]}>
            <tbody>
              {group.group6.map((val, index) => {
                return (
                  <tr key={index}>
                    <td className={styles["founder-table-name"]}>{val.name}</td>
                    <td className={styles["founder-table-title"]}>
                      {val.title}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <h3>{t("ncih_founder_table_sub_title_6")}</h3>
        <div className={styles["founder"]}>
          <table className={styles["founder-table"]}>
            <tbody>
              {group.group7.map((val, index) => {
                return (
                  <tr key={index}>
                    <td className={styles["founder-table-name"]}>{val.name}</td>
                    <td className={styles["founder-table-title"]}>
                      {val.title}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <h3>{t("ncih_founder_table_sub_title_6")}</h3>
        <div className={styles["founder"]}>
          <table className={styles["founder-table"]}>
            <tbody>
              {group.group7.map((val, index) => {
                return (
                  <tr key={index}>
                    <td className={styles["founder-table-name"]}>{val.name}</td>
                    <td className={styles["founder-table-title"]}>
                      {val.title}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <h3>{t("ncih_founder_table_sub_title_7")}</h3>
        <div className={styles["founder"]}>
          <table className={styles["founder-table"]}>
            <tbody>
              {group.group8.map((val, index) => {
                return (
                  <tr key={index}>
                    <td className={styles["founder-table-name"]}>{val.name}</td>
                    <td className={styles["founder-table-title"]}>
                      {val.title}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <h3>{t("ncih_founder_table_sub_title_8")}</h3>
        <div className={styles["founder"]}>
          <table className={styles["founder-table"]}>
            <tbody>
              {group.group9.map((val, index) => {
                return (
                  <tr key={index}>
                    <td className={styles["founder-table-name"]}>{val.name}</td>
                    <td className={styles["founder-table-title"]}>
                      {val.title}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <h3>{t("ncih_founder_table_sub_title_9")}</h3>
        <div className={styles["founder"]}>
          <table className={styles["founder-table"]}>
            <tbody>
              {group.group10.map((val, index) => {
                return (
                  <tr key={index}>
                    <td className={styles["founder-table-name"]}>{val.name}</td>
                    <td className={styles["founder-table-title"]}>
                      {val.title}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <h3>{t("ncih_founder_table_sub_title_10")}</h3>
        <div className={styles["founder"]}>
          <table className={styles["founder-table"]}>
            <tbody>
              {group.group11.map((val, index) => {
                return (
                  <tr key={index}>
                    <td className={styles["founder-table-name"]}>{val.name}</td>
                    <td className={styles["founder-table-title"]}>
                      {val.title}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <h3>{t("ncih_founder_table_sub_title_11")}</h3>
        <div className={styles["founder"]}>
          <table className={styles["founder-table"]}>
            <tbody>
              {group.group12.map((val, index) => {
                return (
                  <tr key={index}>
                    <td className={styles["founder-table-name"]}>{val.name}</td>
                    <td className={styles["founder-table-title"]}>
                      {val.title}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <h3>{t("ncih_founder_table_sub_title_12")}</h3>
        <div className={styles["founder"]}>
          <table className={styles["founder-table"]}>
            <tbody>
              {group.group13.map((val, index) => {
                return (
                  <tr key={index}>
                    <td className={styles["founder-table-name"]}>{val.name}</td>
                    <td className={styles["founder-table-title"]}>
                      {val.title}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <h3>{t("ncih_founder_table_sub_title_13")}</h3>
        <div className={styles["founder"]}>
          <table className={styles["founder-table"]}>
            <tbody>
              {group.group14.map((val, index) => {
                return (
                  <tr key={index}>
                    <td className={styles["founder-table-name"]}>{val.name}</td>
                    <td className={styles["founder-table-title"]}>
                      {val.title}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <h3>{t("ncih_founder_table_sub_title_14")}</h3>
        <div className={styles["founder"]}>
          <table className={styles["founder-table"]}>
            <tbody>
              {group.group15.map((val, index) => {
                return (
                  <tr key={index}>
                    <td className={styles["founder-table-name"]}>{val.name}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <p className={styles["founder-date"]}>
            {t("ncih_founder_table_date_2")}
          </p>
        </div>
      </div>
    </InnerLayout>
  );
};

export default Ncih;
