import React from "react";

// component
import InnerLayout from "components/Layout/InnerLayout";
import Hero from "./section/Hero/Hero";
import Information from "./section/Information/Information";
import Member from "./section/Member/Member";
import World from "./section/World/World";
import News from "./section/News/News";
import Ihic from "./section/Ihic/Ihic";
import Archives from "./section/Archives/Archives";

const Index = () => {
  return (
    <>
      <InnerLayout breadcrumb={[]}>
        <Hero />
        <Information />
        <Member />
        <World />
        <News />
        <Ihic />
        <Archives />
      </InnerLayout>
    </>
  );
};

export default Index;
