import React from "react";
import { Link } from "react-router-dom";

// material ui
import { Button } from "@material-ui/core";

// style
import styles from "./Tags.module.scss";
import clsx from "clsx";

const Tags = ({
  Tags = [
    {
      Full_Name: "すべて",
    },
    {
      Full_Name: "活動ニュース",
    },
    {
      Full_Name: "トピック/おすすめ",
    },
  ],
  path = "tags",
  tag = "すべて",
  className = { container: null, item: null },
}) => {
  return (
    <ul
      className={clsx(styles["tags"], {
        [className.container]: className.container !== null,
      })}
    >
      {Tags.map((val, index) => {
        if (val.Full_Name === "information") return false;
        return (
          <li
            className={clsx(styles["tags-item"], {
              [styles["tags-item-active"]]:
                tag === val.Full_Name.replace("/", "-"),
              [className.item]: className.item !== null,
            })}
            key={index}
          >
            <Button
              component={Link}
              to={
                val.Full_Name === "すべて"
                  ? `/${path}`
                  : `/${path}/tag-${val.Full_Name.replace("/", "-")}`
              }
            >
              {val.Full_Name}
            </Button>
          </li>
        );
      })}
    </ul>
  );
};

export default Tags;
