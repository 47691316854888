import React from "react";

// material ui
import { Typography } from "@material-ui/core";

// style
import styles from "./Typography.module.scss";

const SubTitle = ({ children, align }) => {
  return (
    <Typography className={styles["title-sub"]} align={align}>
      {children}
    </Typography>
  );
};

export default SubTitle;
