import React from "react";
import clsx from "clsx";

// material ui
import { Typography } from "@material-ui/core";

// style
import styles from "./Typography.module.scss";

const HandTitle = ({
  titleImg,
  title,
  variant = "h1",
  maxWidth = 300,
  align,
}) => {
  return (
    <Typography
      className={clsx(styles["title-hand"], {
        [styles["title-hand-left"]]: align === "left",
      })}
      variant={variant}
      align={align}
    >
      <img
        style={{
          maxWidth: `${maxWidth}px`,
        }}
        src={titleImg}
        alt={title}
      />
    </Typography>
  );
};

export default HandTitle;
