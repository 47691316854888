import React from "react";
import { useTranslation } from "react-i18next";

// component
import InnerLayout from "components/Layout/InnerLayout";
import DetailHeader from "components/Cms/DetailHeader";
import PageHeader from "components/PageHeader/PageHeader";
import Helmet from "components/Helmet/Helmet";
import Loading from "components/Loading/Loading";

// styles
import styles from "./Cms.module.scss";

const DisplayArticle = ({ param = null, article }) => {
  const { t } = useTranslation();
  const articleDetail = article?.data?.content_cms_entry_data;

  return (
    <>
      {article === null && (
        <div className={styles["cms-load"]}>
          <Loading />
        </div>
      )}
      {article !== null && article !== false && (
        <InnerLayout
          breadcrumb={[
            {
              to: `/information/${articleDetail.Slug}`,
              text: articleDetail.Title,
            },
          ]}
        >
          <Helmet>
            <title>{`${articleDetail.Title} - ${t("common_ncih")}`}</title>
            <meta
              property="og:title"
              content={`${articleDetail.Title} - ${t("common_ncih")}`}
            />
            <meta
              property="og:site_name"
              content={`${articleDetail.Title} - ${t("common_ncih")}`}
            />
          </Helmet>
          {param === null ? (
            <DetailHeader article={articleDetail} />
          ) : (
            <PageHeader
              title={t(`${param}_title_en`)}
              subTitle={articleDetail.Title}
            />
          )}
          <div
            className={styles["cms-contents"]}
            dangerouslySetInnerHTML={{
              __html: articleDetail.Contents,
            }}
          />
        </InnerLayout>
      )}
    </>
  );
};

export default DisplayArticle;
