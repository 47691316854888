import React from "react";
import { rest } from "@karpeleslab/klbfw";
import { useRest, useRestRefresh } from "@karpeleslab/react-klbfw-hooks";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Toast, { error } from "components/Toast/Toast";

//material ui
import { Box, Typography, Grid } from "@material-ui/core";

// component
import Btn from "components/Btns/Btn";
import Loading from "components/Loading/Loading";

// style
import styles from "../Member.module.scss";

// img
import CreditBrands from "assets/img/img-credit-brand.png";

// const
import { CATALOG_PRODUCT } from "const/const";

const Payment = () => {
  const [products] = useRest("Catalog/Product:search", {
    Catalog_Product__: CATALOG_PRODUCT,
  });
  const history = useHistory();

  const { t } = useTranslation();
  const cartRefresh = useRestRefresh("Catalog/Cart/@");

  const addToCart = (productId) => {
    let num = 1;

    const query = {
      product: productId,
      quantity: num,
    };

    rest("Catalog/Cart/@:add", "POST", query)
      .then(() => {
        cartRefresh();
        history.push("/cart");
      })
      .catch(() => {
        cartRefresh();
        error(t("toast_error_add_product"), {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  return (
    <Box className={styles["page-container"]}>
      <Typography className={styles["member-title"]} variant="h2">
        {t("member_page_payment_title")}
      </Typography>
      {products === null && <Loading />}
      {products !== null && (
        <Grid
          className={styles["member-payment"]}
          container
          justify="space-between"
        >
          <Grid className={styles["member-payment-item"]} item>
            <Typography className={styles["member-title-sub"]} variant="h3">
              {t("member_page_payment_credit_title")}
            </Typography>
            <figure className={styles["member-payment-img"]}>
              <img src={CreditBrands} />
            </figure>
            <p
              className={styles["member-payment-text"]}
              dangerouslySetInnerHTML={{
                __html: t("member_page_payment_credit_text"),
              }}
            ></p>
            <Btn
              className={styles["member-payment-btn"]}
              onClick={() => {
                addToCart(CATALOG_PRODUCT);
              }}
            >
              {t("member_page_payment_application")}
            </Btn>
            <Btn fileType="pdf" to="/pdf/application2021.pdf">
              {t("member_page_payment_member_application")}
            </Btn>
          </Grid>
          <Grid className={styles["member-payment-item"]} item>
            <Typography className={styles["member-title-sub"]} variant="h3">
              {t("member_page_payment_bank_title")}
            </Typography>
            <Typography className={styles["member-payment-sub"]} variant="h4">
              {t("member_page_payment_bank_title_sub")}
            </Typography>
            <p
              className={styles["member-payment-text"]}
              dangerouslySetInnerHTML={{
                __html: t("member_page_payment_bank_text"),
              }}
            ></p>
            <Btn fileType="pdf" to="/pdf/application2021.pdf">
              {t("member_page_payment_member_application")}
            </Btn>
          </Grid>
        </Grid>
      )}
      <Toast duration={5000} />
    </Box>
  );
};

export default Payment;
