import React from "react";
import { useTranslation } from "react-i18next";

//material ui
import { Grid } from "@material-ui/core";
import { Box } from "@material-ui/core";

// component
import TypoGroup from "components/Typography/TypoGroup";

// style
import styles from "../Ncih.module.scss";

// img
import title from "assets/img/title-nich-purpose.png";
import img from "assets/img/img-ncih-purpose.png";

const Purpose = () => {
  const { t } = useTranslation();

  return (
    <div className={styles["purpose"]}>
      <Box className={styles["page-container"]}>
        <Grid container>
          <Grid item xs={12} md={3} lg={3}>
            <TypoGroup
              titleImg={title}
              title={t("ncih_purpose_title")}
              subtitle={t("ncih_purpose_sub_title")}
              variant="h2"
              maxWidth={228}
            />
          </Grid>
          <Grid item xs={12} md={9} lg={9} className={styles["purpose-text"]}>
            <p>{t("ncih_purpose_text_1")}</p>
            <p>{t("ncih_purpose_text_2")}</p>
            <p>{t("ncih_purpose_text_3")}</p>
            <p>{t("ncih_purpose_text_4")}</p>
            <p>{t("ncih_purpose_text_5")}</p>
            <p className={styles["purpose-text-right"]}>
              {t("ncih_purpose_text_user")}
            </p>
          </Grid>
        </Grid>
      </Box>
      <div className={styles["purpose-img"]}>
        <img src={img} alt="" />
      </div>
    </div>
  );
};

export default Purpose;
