import React from "react";
import { useTranslation } from "react-i18next";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

//material ui
import { Grid } from "@material-ui/core";

// components
import Btn from "components/Btns/Btn";
import TypoGroup from "components/Typography/TypoGroup";
import ListNews from "components/List/ListNews";
import Loading from "components/Loading/Loading";

// img
import title from "assets/img/title-news.png";

// style
import styles from "./News.module.scss";

// constant
import { NEWS_PAGE_COUNT_TOP } from "const/const";

const News = () => {
  const { t } = useTranslation();
  const [news] = useRest("Content/Cms/@information:search", {
    results_per_page: NEWS_PAGE_COUNT_TOP,
    sort: "published:desc",
    query: {
      tag: "news",
    },
  });

  return (
    <div className={styles["news-container"]}>
      <Grid container>
        <Grid item xs={12} md={4} lg={4} className={styles["world-img"]}>
          <TypoGroup
            titleImg={title}
            title="News"
            subtitle={t("news_sub_title")}
            variant="h2"
            maxWidth={96}
            align="left"
          />
        </Grid>
        <Grid item xs={12} md={8} lg={8} className={styles["world-info"]}>
          <div className={styles["news-list"]}>
            {news === null && <Loading />}
            {news !== null && <ListNews vals={news?.data.data} />}
          </div>
          <div className={styles["news-btn"]}>
            <Btn to="/news">{t("common_btn_view_alll")}</Btn>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default News;
