import React from "react";

// component
import ListBtn from "./ListBtn";

// style
import styles from "./Btn.module.scss";

const ListBtnGroup = ({
  btns = [
    {
      text: "default",
      to: "/",
    },
    {
      text: "default",
      to: "/",
    },
  ],
}) => {
  return (
    <>
      <div
        className={`${styles["btn-list-group"]} ${
          styles[`btn-list-group-${btns.length}`]
        }`}
      >
        {btns.map((val, index) => {
          return (
            <ListBtn to={val.to} key={index}>
              {val.text}
            </ListBtn>
          );
        })}
      </div>
    </>
  );
};

export default ListBtnGroup;
