import React from "react";
import { useTranslation } from "react-i18next";

//material ui
import { Box, Typography } from "@material-ui/core";

// component
import InnerLayout from "components/Layout/InnerLayout";
import PageHeader from "components/PageHeader/PageHeader";
import Helmet from "components/Helmet/Helmet";
import ArchiveList from "components/List/ArchiveList";

// style
import styles from "./Archives.module.scss";

// img
import PageHeaderImg from "assets/img/img-page-header-archives.png";
import hagi1 from "assets/img/img-archives-hagi-01.png";
import hagi2 from "assets/img/img-archives-hagi-02.png";
import hagi3 from "assets/img/img-archives-hagi-03.png";
import kagoshima1 from "assets/img/img-archives-kagoshima-01.png";
import kagoshima2 from "assets/img/img-archives-kagoshima-02.png";
import nirayama1 from "assets/img/img-archives-nirayama.png";
import kamaishi1 from "assets/img/img-archives-kamaishi.png";
import saga1 from "assets/img/img-archives-saga.png";
import nagasaki1 from "assets/img/img-archives-nagasaki-01.png";
import nagasaki2 from "assets/img/img-archives-nagasaki-02.png";
import miike1 from "assets/img/img-archives-miike-01.png";
import miike2 from "assets/img/img-archives-miike-02.png";
import miike3 from "assets/img/img-archives-miike-03.png";

const Ncih = () => {
  const { t } = useTranslation();
  const array = {
    hagi: [
      {
        img: hagi1,
        text: t("archives_title_hagi_1"),
      },
      {
        img: hagi2,
        text: t("archives_title_hagi_2"),
      },
      {
        img: hagi3,
        text: t("archives_title_hagi_3"),
      },
    ],
    kagoshima: [
      {
        img: kagoshima1,
        text: t("archives_title_kagoshima_1"),
      },
      {
        img: kagoshima2,
        text: t("archives_title_kagoshima_2"),
      },
    ],
    nirayama: [
      {
        img: nirayama1,
        text: t("archives_title_nirayama_1"),
      },
    ],
    kamaishi: [
      {
        img: kamaishi1,
        text: t("archives_title_kamaishi_1"),
      },
    ],
    saga: [
      {
        img: saga1,
        text: t("archives_title_saga_1"),
      },
    ],
    nagasaki: [
      {
        img: nagasaki1,
        text: t("archives_title_nagasaki_1"),
      },
      {
        img: nagasaki2,
        text: t("archives_title_nagasaki_2"),
      },
    ],
    miike: [
      {
        img: miike1,
        text: t("archives_title_miike_1"),
      },
      {
        img: miike2,
        text: t("archives_title_miike_2"),
      },
      {
        img: miike3,
        text: t("archives_title_miike_3"),
      },
    ],
  };

  return (
    <InnerLayout
      breadcrumb={[
        {
          to: "",
          text: t("archives_sub_title"),
        },
      ]}
    >
      <Helmet>
        <title>{`${t("archives_sub_title")} - ${t("common_ncih")}`}</title>
        <meta
          property="og:title"
          content={`${t("archives_sub_title")} - ${t("common_ncih")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("archives_sub_title")} - ${t("common_ncih")}`}
        />
      </Helmet>
      <PageHeader
        title={t("archives_title")}
        subTitle={t("archives_sub_title")}
        bgImg={PageHeaderImg}
      />
      <Box
        className={`${styles["page-container"]} ${styles["archives-container"]}`}
      >
        <Typography className={styles["archives-text"]} variant="body1">
          {t("archives_text")}
        </Typography>
        <Typography className={styles["archives-title"]} variant="h2">
          {t("archives_title_hagi")}
        </Typography>
        <ArchiveList array={array.hagi} />
      </Box>

      <Box
        className={`${styles["page-container"]} ${styles["archives-container"]}`}
      >
        <Typography className={styles["archives-title"]} variant="h2">
          {t("archives_title_kagoshima")}
        </Typography>
        <ArchiveList array={array.kagoshima} />
      </Box>

      <Box
        className={`${styles["page-container"]} ${styles["archives-container"]}`}
      >
        <Typography className={styles["archives-title"]} variant="h2">
          {t("archives_title_nirayama")}
        </Typography>
        <ArchiveList array={array.nirayama} />
      </Box>

      <Box
        className={`${styles["page-container"]} ${styles["archives-container"]}`}
      >
        <Typography className={styles["archives-title"]} variant="h2">
          {t("archives_title_kamaishi")}
        </Typography>
        <ArchiveList array={array.kamaishi} />
      </Box>

      <Box
        className={`${styles["page-container"]} ${styles["archives-container"]}`}
      >
        <Typography className={styles["archives-title"]} variant="h2">
          {t("archives_title_saga")}
        </Typography>
        <ArchiveList array={array.saga} />
      </Box>

      <Box
        className={`${styles["page-container"]} ${styles["archives-container"]}`}
      >
        <Typography className={styles["archives-title"]} variant="h2">
          {t("archives_title_nagasaki")}
        </Typography>
        <ArchiveList array={array.nagasaki} />
      </Box>

      <Box
        className={`${styles["page-container"]} ${styles["archives-container"]}`}
      >
        <Typography className={styles["archives-title"]} variant="h2">
          {t("archives_title_miike")}
        </Typography>
        <ArchiveList array={array.miike} />
      </Box>
    </InnerLayout>
  );
};

export default Ncih;
