import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { rest } from "@karpeleslab/klbfw";

// component
import InputForm from "components/Forms/Input";
import Button from "components/Btns/Btn";
import PasswordModal from "components/Password/Password";
import Toast, { error, success } from "components/Toast/Toast";

// styles
import styles from "../MyPage.module.scss";

const Password = () => {
  const { t } = useTranslation();
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);

  const submitForm = (password) => {
    setLoad(true);

    const data = {
      old_password: password,
      password: newPassword,
    };

    rest("User/@:setPassword", "POST", data)
      .then(() => {
        setOpen(false);
        setLoad(false);
        setNewPassword("");
        setNewPasswordConfirm("");
        success("toast_success_text", true, {
          position: "top-center",
          autoClose: 1500,
        });
      })
      .catch((err) => {
        setLoad(false);
        error(err.message, false, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  return (
    <div>
      <InputForm
        type="password"
        value={newPassword}
        name="user-newPass"
        label={t("mypage_password_new")}
        placeholder={t("input_field_placeholder", {
          text: t("mypage_password_new"),
        })}
        handleChange={(event) => {
          setNewPassword(event.target.value);
        }}
        helperText={t("mypage_password_text")}
        disabled={load}
      />
      <InputForm
        type="password"
        value={newPasswordConfirm}
        name="user-newPassConfirm"
        label={t("mypage_password_new_confirm")}
        placeholder={t("input_field_placeholder", {
          text: t("mypage_password_new_confirm"),
        })}
        handleChange={(event) => {
          setNewPasswordConfirm(event.target.value);
        }}
      />
      <div className={styles["mypage-btns"]}>
        <Button
          onClick={() => {
            setOpen(true);
          }}
          iconPosition="noting"
          disabled={
            newPassword !== newPasswordConfirm ||
            (newPassword.length < 6 && newPasswordConfirm.length < 6) ||
            load
          }
        >
          {t("common_save")}
        </Button>
      </div>
      <PasswordModal
        disabled={load}
        setOpen={setOpen}
        open={open}
        submit={submitForm}
      />
      <Toast duration={5000} />
    </div>
  );
};

export default Password;
