import React from "react";
import { useTranslation } from "react-i18next";
import { useWindowWidth } from "@react-hook/window-size";

// material ui
import { Typography } from "@material-ui/core";

// components
import Btn from "components/Btns/Btn";

// img
import bg from "assets/img/bg-index-archives.png";
import img from "assets/img/img-index-archives.png";

// style
import styles from "./Archives.module.scss";

const Archives = () => {
  const { t } = useTranslation();
  const onlyWidth = useWindowWidth();
  return (
    <div className={styles["archives-container"]}>
      <div
        className={styles["archives-bg"]}
        style={{
          backgroundImage: `url(${bg})`,
        }}
      ></div>
      <div className={styles["archives-inner"]}>
        <div className={styles["archives-img"]}>
          <img src={img} alt="" />
        </div>
        <div className={styles["archives-info"]}>
          <Typography variant="h2" className={styles["archives-info-title"]}>
            {t("index_arvhives_title")}
          </Typography>
          <Typography component="p" className={styles["archives-info-text"]}>
            <span
              dangerouslySetInnerHTML={{ __html: t("index_arvhives_text") }}
            />
          </Typography>
          <div className={styles["archives-info-btn"]}>
            <Btn
              type={onlyWidth < 1080 ? "primary" : "secondary"}
              to="/archives"
            >
              {t("common_archives")}
            </Btn>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Archives;
