import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useRest, useRestRefresh } from "@karpeleslab/react-klbfw-hooks";
import { useTranslation } from "react-i18next";
import { rest } from "@karpeleslab/klbfw";
import qs from "qs";
import Toast, { error } from "components/Toast/Toast";

// material ui
import { Box } from "@material-ui/core";

// component
import InnerLayout from "components/Layout/InnerLayout";
import PageHeader from "components/PageHeader/PageHeader";
import Helmet from "components/Helmet/Helmet";
import Loading from "components/Loading/Loading";
import Button from "components/Btns/Btn";
import CartList from "components/List/CartList";

// style
import styles from "./Cart.module.scss";

const Cart = () => {
  const { t } = useTranslation();
  const [cart] = useRest("Catalog/Cart/@");
  const cartRefresh = useRestRefresh("Catalog/Cart/@");
  const location = useLocation();
  const hash = qs.parse(location.search.split("?")[1]);
  const history = useHistory();

  useEffect(() => {
    addToCart(hash.product);
  }, []);

  const addToCart = (hash) => {
    if (hash === void 0) {
      return false;
    }

    const query = {
      request: hash,
    };

    rest("Catalog/Cart/@:process", "POST", query)
      .then(() => {
        cartRefresh();
        history.push(`/redirect?path=cart`);
      })
      .catch((err) => {
        error(`error_${err.token}`, true, {
          position: "top-center",
          autoClose: 3000,
        });
      });
  };

  return (
    <InnerLayout
      breadcrumb={[
        {
          to: "",
          text: t("cart_sub_title"),
        },
      ]}
    >
      <Helmet>
        <title>{`${t("cart_sub_title")} - ${t("common_ncih")}`}</title>
        <meta
          property="og:title"
          content={`${t("cart_sub_title")} - ${t("common_ncih")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("cart_sub_title")} - ${t("common_ncih")}`}
        />
      </Helmet>
      <PageHeader title={t("cart_title")} subTitle={t("cart_sub_title")} />

      <Box className={`${styles["page-container"]}`}>
        {cart === null && <Loading />}
        {cart !== null && cart?.data.products.length === 0 && (
          <>
            <p className={styles["cart-item-no"]}>{t("cart_label_no_item")}</p>
            <div className={styles["cart-btns"]}>
              <Button
                iconPosition="start"
                onClick={() => {
                  history.goBack();
                }}
              >
                {t("paginate_prev")}
              </Button>
            </div>
          </>
        )}
        {cart !== null && cart?.data.products.length !== 0 && (
          <>
            <CartList cart={cart} deleteBtn={true} />
            <div className={styles["cart-btns"]}>
              <Button to="/checkout">{t("cart_label_to_checkout")}</Button>
            </div>
          </>
        )}
      </Box>
      <Toast duration={5000} />
    </InnerLayout>
  );
};

export default Cart;
