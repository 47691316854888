import React, { useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

// material ui
import Button from "@material-ui/core/Button";

// styles
import styles from "./Btn.module.scss";

// img
import iconArrowBlack from "assets/img/icon-arrow-black.png";
import iconArrowWhite from "assets/img/icon-arrow-white.png";
import iconBlankWhite from "assets/img/icon-blank-white.png";

const Btn = ({
  btnTypes = "button",
  type = "primary",
  children = "default",
  fileType = null,
  to = "/",
  onClick = null,
  icon = null,
  className = null,
  disabled = false,
  iconPosition = "end",
}) => {
  const [btnType] = useState(type === "primary");

  const btnLink = () => {
    if (to.indexOf("http") != -1 || fileType === "pdf") {
      return (
        <Button
          className={clsx(styles["btn"], {
            [styles["btn-primary"]]: type === "primary",
            [styles["btn-secondary"]]: type === "secondary",
            [styles["btn-danger"]]: type === "danger",
            [className]: className !== null,
          })}
          component="a"
          endIcon={
            <img
              src={
                btnType
                  ? icon === "blank"
                    ? iconBlankWhite
                    : iconArrowWhite
                  : iconArrowBlack
              }
            />
          }
          href={to}
          target="_blank"
          rel="noreferrer"
          disabled={disabled}
        >
          {children}
        </Button>
      );
    } else if (btnTypes === "submit") {
      return (
        <Button
          className={clsx(styles["btn"], {
            [styles["btn-primary"]]: type === "primary",
            [styles["btn-secondary"]]: type === "secondary",
            [styles["btn-danger"]]: type === "danger",
            [className]: className !== null,
          })}
          type="submit"
          endIcon={<img src={btnType ? iconArrowWhite : iconArrowBlack} />}
          disabled={disabled}
        >
          {children}
        </Button>
      );
    } else {
      if (iconPosition === "end") {
        return (
          <Button
            className={clsx(styles["btn"], {
              [styles["btn-primary"]]: type === "primary",
              [styles["btn-secondary"]]: type === "secondary",
              [styles["btn-danger"]]: type === "danger",
              [className]: className !== null,
            })}
            component={Link}
            endIcon={<img src={btnType ? iconArrowWhite : iconArrowBlack} />}
            to={to}
            disabled={disabled}
          >
            {children}
          </Button>
        );
      } else if (iconPosition === "start") {
        return (
          <Button
            className={clsx(styles["btn"], {
              [styles["btn-primary"]]: type === "primary",
              [styles["btn-secondary"]]: type === "secondary",
              [styles["btn-danger"]]: type === "danger",
              [className]: className !== null,
            })}
            component={Link}
            startIcon={<img src={btnType ? iconArrowWhite : iconArrowBlack} />}
            to={to}
            disabled={disabled}
          >
            {children}
          </Button>
        );
      } else {
        return (
          <Button
            className={clsx(styles["btn"], {
              [styles["btn-primary"]]: type === "primary",
              [styles["btn-secondary"]]: type === "secondary",
              [styles["btn-danger"]]: type === "danger",
              [className]: className !== null,
            })}
            component={Link}
            to={to}
            disabled={disabled}
          >
            {children}
          </Button>
        );
      }
    }
  };

  const btn = () => {
    if (iconPosition === "end") {
      return (
        <Button
          className={clsx(styles["btn"], {
            [styles["btn-primary"]]: type === "primary",
            [styles["btn-secondary"]]: type === "secondary",
            [styles["btn-danger"]]: type === "danger",
            [className]: className !== null,
          })}
          endIcon={<img src={btnType ? iconArrowWhite : iconArrowBlack} />}
          onClick={() => onClick()}
          disabled={disabled}
        >
          {children}
        </Button>
      );
    } else if (iconPosition === "start") {
      return (
        <Button
          className={clsx(`${styles["btn"]} ${styles["btn__revers"]}`, {
            [styles["btn-primary"]]: type === "primary",
            [styles["btn-secondary"]]: type === "secondary",
            [styles["btn-danger"]]: type === "danger",
            [className]: className !== null,
          })}
          startIcon={<img src={btnType ? iconArrowWhite : iconArrowBlack} />}
          onClick={() => onClick()}
          disabled={disabled}
        >
          {children}
        </Button>
      );
    } else {
      return (
        <Button
          className={clsx(`${styles["btn"]}`, {
            [styles["btn-primary"]]: type === "primary",
            [styles["btn-secondary"]]: type === "secondary",
            [styles["btn-danger"]]: type === "danger",
            [className]: className !== null,
          })}
          onClick={() => onClick()}
          disabled={disabled}
        >
          {children}
        </Button>
      );
    }
  };

  const buildBtn = () => {
    switch (onClick) {
      case null:
        return btnLink();
      default:
        return btn();
    }
  };

  return <>{buildBtn()}</>;
};

export default Btn;
