import React from "react";
import { useHistory } from "react-router-dom";

// material ui
import { Button } from "@material-ui/core";

// style
import styles from "./Tags.module.scss";

const TagsList = ({
  Tags = [
    {
      Full_Name: "すべて",
    },
  ],
  path = "tags",
}) => {
  const hisotry = useHistory();
  return (
    <ul className={styles["tags-list"]}>
      {Tags.map((val, index) => {
        if (val.Full_Name === "information") return false;
        return (
          <li className={styles["tags-list-item"]} key={index}>
            <Button
              onClick={() => {
                hisotry.push(`/${path}/${val.Full_Name}`);
              }}
            >
              {val.Full_Name}
            </Button>
          </li>
        );
      })}
    </ul>
  );
};

export default TagsList;
