import React from "react";
import { useTranslation } from "react-i18next";

//material ui
import { Box, Typography } from "@material-ui/core";

// style
import styles from "../Member.module.scss";

const Benefits = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box
        className={`${styles["page-container"]} ${styles["member-text-group"]}`}
      >
        <p dangerouslySetInnerHTML={{ __html: t("member_page_top_text") }}></p>
      </Box>
      <Box className={styles["page-container"]}>
        <Typography className={styles["member-title"]} variant="h2">
          {t("member_page_title_fee")}
        </Typography>
        <div className={styles["member-table-container"]}>
          <table className={styles["member-table"]}>
            <tbody>
              <tr>
                <td>{t("member_page_special_member")}</td>
                <td className={styles["member-table-right"]}>
                  {t("member_page_100000yen")}
                </td>
                <td className={styles["member-table-right"]}>
                  {t("member_page_10more")}
                </td>
              </tr>
              <tr>
                <td>{t("member_page_ordinary_member")}</td>
                <td className={styles["member-table-right"]}>
                  {t("member_page_100000yen")}
                </td>
                <td className={styles["member-table-right"]}>
                  {t("member_page_1more")}
                </td>
              </tr>
              <tr>
                <td>{t("member_page_individual_member")}</td>
                <td className={styles["member-table-right"]}>
                  {t("member_page_5000yen")}
                </td>
                <td className={styles["member-table-right"]}>
                  {t("member_page_1more")}
                </td>
              </tr>
            </tbody>
          </table>
          <Typography
            className={styles["member-table-caption"]}
            variant="body1"
          >
            {t("member_page_please")}
          </Typography>
        </div>
      </Box>
    </>
  );
};

export default Benefits;
