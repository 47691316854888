import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

//material ui
import { Box } from "@material-ui/core";

// component
import Helmet from "components/Helmet/Helmet";
import InnerLayout from "components/Layout/InnerLayout";
import PageHeader from "components/PageHeader/PageHeader";
import Btn from "components/Btns/Btn";

// style
import styles from "./NotFound.module.scss";

const NotFound = ({ backType = "top" }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <InnerLayout
      breadcrumb={[
        {
          to: "",
          text: t("notfound_sub_title"),
        },
      ]}
    >
      <Helmet>
        <title>{`${t("notfound_sub_title")} - ${t("common_ncih")}`}</title>
        <meta
          property="og:title"
          content={`${t("notfound_sub_title")} - ${t("common_ncih")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("notfound_sub_title")} - ${t("common_ncih")}`}
        />
      </Helmet>
      <PageHeader
        title={t("notfound_title")}
        subTitle={t("notfound_sub_title")}
      />
      <div className={styles["notFound-container"]}>
        <Box
          className={`${styles["page-container"]} ${styles["notFound-text-container"]}`}
        >
          <p dangerouslySetInnerHTML={{ __html: t("notfound_text") }} />
        </Box>
        <div className={styles["notFound-btn-container"]}>
          <Box
            className={`${styles["page-container"]} ${styles["notFound-btn-container-inner"]}`}
          >
            {backType === "top" ? (
              <Btn to="/">{t("common_btn_top")}</Btn>
            ) : (
              <Btn
                onClick={() => {
                  history.goBack();
                }}
              >
                {t("paginate_prev")}
              </Btn>
            )}
          </Box>
        </div>
      </div>
    </InnerLayout>
  );
};

export default NotFound;
