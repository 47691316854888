import React, { useState } from "react";
import { TwitterTimelineEmbed } from "react-twitter-embed";

// material ui
import CircularProgress from "@material-ui/core/CircularProgress";

// style
import styles from "./Sns.module.scss";

const SnsList = ({ name }) => {
  const [load, setLoad] = useState(true);

  return (
    <div className={styles["sns-container"]}>
      {load && (
        <div className={styles["sns-load"]}>
          <CircularProgress color="secondary" />
        </div>
      )}
      <TwitterTimelineEmbed
        sourceType="profile"
        screenName={name}
        options={{ id: `profile：${name}`, height: 400 }}
        onLoad={() => {
          setLoad(false);
        }}
      />
    </div>
  );
};

export default SnsList;
