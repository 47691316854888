import React from "react";
import { useTranslation } from "react-i18next";

// components
import Sns from "./Sns";

// style
import styles from "./Sns.module.scss";

const SnsList = () => {
  const { t } = useTranslation();

  return (
    <div className={styles["sns"]}>
      <div className={styles["sns-item"]}>
        <h2 className={styles["sns-item-title"]}>{t("common_sns_ihic")}</h2>
        <div className={styles["sns-item-inner"]}>
          <Sns name="sangyo_isan" />
        </div>
      </div>
      <div className={styles["sns-item"]}>
        <h2 className={styles["sns-item-title"]}>{t("common_sns_meiji")}</h2>
        <div className={styles["sns-item-inner"]}>
          <Sns name="officialjmir" />
        </div>
      </div>
      <div className={styles["sns-item"]}>
        <h2 className={styles["sns-item-title"]}>
          {t("common_sns_gunkanjima")}
        </h2>
        <div className={styles["sns-item-inner"]}>
          <Sns name="gunkantruth" />
        </div>
      </div>
    </div>
  );
};

export default SnsList;
