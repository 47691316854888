import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// compponent
import SnsList from "components/Sns/SnsList";

// style
import styles from "./Footer.module.scss";

// img
import Logo from "assets/img/logo-black.png";
import blankIcon from "assets/img/icon-window-blank.svg";
import homeIcon from "assets/img/icon-home.svg";

const Footer = ({ breadcrumb = [] }) => {
  const { t } = useTranslation();

  const buildBreadcrumb = () => {
    let content = [];

    const inLink = (
      <Link to="/">
        <img src={homeIcon} alt="" />
        {t("common_breadcrumb_top")}
      </Link>
    );

    const noLink = (
      <>
        <img src={homeIcon} alt="" />
        {t("common_breadcrumb_top")}
      </>
    );

    content.push(<li key={0}>{breadcrumb.length === 0 ? noLink : inLink}</li>);

    if (breadcrumb.length === 0) return content;

    for (let num = 0; breadcrumb.length > num; num++) {
      content.push(
        <li key={num + 1}>
          {breadcrumb[num].to === "" ? (
            breadcrumb[num].text
          ) : (
            <Link to={breadcrumb[num].to}>{breadcrumb[num].text}</Link>
          )}
        </li>
      );
    }

    return content;
  };

  return (
    <>
      <SnsList />
      <footer className={styles["footer"]}>
        <ul className={styles["footer-to-top"]}>{buildBreadcrumb()}</ul>
        <div className={styles["footer-inner"]}>
          <div className={styles["footer-related"]}>
            <h2 className={styles["footer-related-title"]}>
              {t("common_related_title")}
            </h2>
            <ul className={styles["footer-related-list"]}>
              <li className={styles["footer-related-item"]}>
                <a
                  href="http://www.japansmeijiindustrialrevolution.com/"
                  rel="noreferrer"
                  target="_blank"
                >
                  {t("common_related_meiji")}
                  <span className={styles["footer-related-item-icon"]}>
                    <img src={blankIcon} />
                  </span>
                </a>
              </li>
              <li className={styles["footer-related-item"]}>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.gunkanjima-truth.com/"
                >
                  {t("common_related_gunkanjima")}
                  <span className={styles["footer-related-item-icon"]}>
                    <img src={blankIcon} />
                  </span>
                </a>
              </li>
              <li className={styles["footer-related-item"]}>
                <a href="https://www.ihic.jp/" rel="noreferrer" target="_blank">
                  {t("common_related_ihic")}
                  <span className={styles["footer-related-item-icon"]}>
                    <img src={blankIcon} />
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div className={styles["footer-list-inner"]}>
            <ul className={styles["footer-list"]}>
              <li className={styles["footer-list-about"]}>
                <Link to="/ncih">{t("common_ncih_about")}</Link>
                <ul>
                  <li>
                    <Link to="/ncih/founder">{t("common_founder")}</Link>
                  </li>
                  <li>
                    <Link to="/ncih/organizational">
                      {t("common_organizational")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/information">{t("common_info")}</Link>
              </li>
            </ul>
            <ul className={styles["footer-list"]}>
              <li className={styles["footer-list-member"]}>
                <Link to="/member">
                  {t("common_support_member_recruitment")}
                </Link>
                {/* <ul>
                  <li>
                    <Link to="/member/list">
                      {t("common_support_member_list")}
                    </Link>
                  </li>
                </ul> */}
              </li>
              <li>
                <Link to="/news">{t("common_news")}</Link>
              </li>
            </ul>
            <ul className={styles["footer-list"]}>
              <li>
                <Link to="/ihic">{t("common_ihic")}</Link>
              </li>
              <li>
                <Link to="/world">{t("common_world")}</Link>
              </li>
              <li>
                <Link to="/support">{t("common_support")}</Link>
              </li>
              <li>
                <Link to="/archives">{t("common_archives")}</Link>
              </li>
            </ul>
          </div>
          <div className={styles["footer-copy-container"]}>
            <ul className={styles["footer-copy-athor"]}>
              <li>
                <Link to="/page/tos">{t("common_terms_of_use")}</Link>
              </li>
              <li>
                <Link to="/page/scta">{t("common_scta")}</Link>
              </li>
              <li>
                <Link to="/page/privacy">{t("common_privacy")}</Link>
              </li>
            </ul>
            <div className={styles["footer-copy-inner"]}>
              <span className={styles["footer-copy-logo"]}>
                <img src={Logo} alt={t("common_ncih")} />
              </span>
              <small className={styles["footer-copy"]}>
                {t("common_copy", { year: new Date().getFullYear() })}
              </small>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
