import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

// material ui
import { Typography } from "@material-ui/core";
import styles from "./PageHeader.module.scss";

// utils
import { breakedText } from "utils/BreakedText";

// img
import PageHeaderCatch from "assets/img/img-page-header-catch.png";

const HandTitle = ({
  title = "defa¥nult",
  subTitle = "default",
  bgImg = null,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx(styles["container"], {
        [styles["container-noimg"]]: bgImg === null,
      })}
    >
      <div className={styles["title-wrapper"]}>
        <Typography className={styles["title"]} variant="h1">
          {breakedText(title)}
        </Typography>
        <Typography className={styles["sub-title"]} variant="body1">
          {subTitle}
        </Typography>
      </div>
      {bgImg !== null && (
        <div
          className={styles["main-visual"]}
          style={{
            backgroundImage: `url(${bgImg})`,
          }}
        >
          <div className={styles["main-visual-catch"]}>
            <img src={PageHeaderCatch} alt={t("common_catch_copy")} />
          </div>
        </div>
      )}
    </div>
  );
};

export default HandTitle;
