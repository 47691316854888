import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

// utile
import { buildDate } from "utils/BuildDate";
import { abridgementText } from "utils/AbridgementText";

// component
import TagsList from "components/Tags/TagsLinst";

// style
import styles from "./Cards.module.scss";

// const
import { IMG_VARIATION } from "const/const";

// img
import thumbnail from "assets/img/img-cms-thumbnail-default.jpg";

const Card = ({ val = null, path = "information", type = "slide" }) => {
  return (
    <div
      className={clsx(styles["card"], {
        [styles["card-list"]]: type === "list",
      })}
    >
      <Link to={`/${path}/${val.Slug}`}>
        <div className={styles["card-date"]}>
          <div className={styles["card-date-top"]}>
            {buildDate(parseInt(val.Published.unixms), "YYYY MM")}
          </div>
          <div>{buildDate(parseInt(val.Published.unixms), "DD")}</div>
        </div>
        <figure className={styles["card-figure"]}>
          <img
            src={
              val.Drive_Item__ !== null
                ? val.Drive_Item.Media_Image[IMG_VARIATION]
                : thumbnail
            }
            alt={val.Title}
          />
        </figure>
        <div className={styles["card-text"]}>
          <TagsList Tags={val.Tags} path="tags" />
          <div className={styles["card-text-title"]}>
            {abridgementText(val.Title, 40)}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Card;
