import React from "react";
import { useTranslation } from "react-i18next";

// material ui
import { Typography } from "@material-ui/core";

// components
import Btn from "components/Btns/Btn";
// img
import Img from "assets/img/img-index-ihic.png";

// style
import styles from "./Ihic.module.scss";

const Ihic = () => {
  const { t } = useTranslation();
  return (
    <div className={styles["ihic-container"]}>
      <div className={styles["ihic-img"]}>
        <img src={Img} alt="" />
      </div>
      <div className={styles["ihic-info"]}>
        <Typography variant="h2" className={styles["ihic-info-title"]}>
          {t("common_ihic")}
        </Typography>
        <Typography component="p" className={styles["ihic-info-text"]}>
          <span dangerouslySetInnerHTML={{ __html: t("index_ihic_text") }} />
        </Typography>
        <div className={styles["ihic-info-btn"]}>
          <Btn to="/ihic">{t("index_ihic_btn")}</Btn>
        </div>
      </div>
    </div>
  );
};

export default Ihic;
