import React from "react";

// component
import Btn from "./Btn";

// style
import styles from "./Btn.module.scss";

const BtnGroup = ({
  btns = [
    {
      text: "default",
      to: "/",
    },
    {
      text: "default",
      to: "/",
    },
  ],
}) => {
  return (
    <>
      <div className={styles["btn-group"]}>
        {btns.map((val, index) => {
          return (
            <div key={index} className={styles["btn-group-item"]}>
              <Btn to={val.to}>{val.text}</Btn>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default BtnGroup;
