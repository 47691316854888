import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { useRest } from "@karpeleslab/react-klbfw-hooks";
import { useTranslation } from "react-i18next";
import { rest, Get } from "@karpeleslab/klbfw";
import clsx from "clsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// component
import InnerLayout from "components/Layout/InnerLayout";
import PageHeader from "components/PageHeader/PageHeader";
import Loading from "components/Loading/Loading";
import InputForm from "components/Forms/Input";
import CheckBox from "components/Forms/CheckBox";
import Button from "components/Btns/Btn";
import LoadingOverLay from "components/Loading/LoadingOverLay";

// styles
import styles from "./Login.module.scss";

const Login = () => {
  const { t } = useTranslation();
  const [session, setSession] = useState(null);
  const [flow, updateFlow] = useState(null);
  const [user, setUser] = useRest("User:get");
  const [form, setForm] = useState({
    email: "",
    name: "",
    password: "",
    password2: "",
    i_accept_the_terms_of_service: false,
  });
  const [loading, setLoading] = useState(false);

  const postHandller = (obj) => {
    rest("User:flow", "POST", obj).then(handleResponse).catch(handleError);
  };

  const submitTrigger = (action, event = null) => {
    if (event !== null) {
      event.preventDefault();
    }

    setLoading(true);

    switch (action.type) {
      case "submit":
        postHandller({ ...form, session: session });
        return;
      case "post":
        postHandller({ session: session, ...action, type: undefined });
        return;
      case "reset":
        postHandller({});
        return;
      default:
        return null;
    }
  };

  const handleResponse = (response) => {
    if (response.data.url) {
      window.location.href = response.data.url;
      return;
    }

    if (response.data.complete) {
      setUser({
        data: response.data.user,
      });
      return;
    }

    if (response.data.session) {
      setSession(response.data.session);
    }

    updateFlow(response);
    setLoading(false);
  };

  const handleError = (response) => {
    toast.error(response.message, {
      position: "top-center",
      autoClose: 3000,
    });
  };

  const formChange = (event) => {
    const array = {};
    array[event.target.getAttribute("name")] =
      event.target.type !== "checkbox"
        ? event.target.value
        : event.target.checked;

    setForm({
      ...form,
      ...array,
    });
  };

  const loginOAuth2 = ({ button, info }) => {
    return (
      <button
        variant="contained"
        style={{
          backgroundImage: `url(${button.logo})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "70% 70%",
          borderRadius: 50,
          backgroundColor: button["background-color"],
        }}
        onClick={() =>
          submitTrigger({ type: "post", oauth2: info.OAuth2_Consumer__ })
        }
      >
        &nbsp;
      </button>
    );
  };

  useEffect(() => {
    if (Get("session")) {
      submitTrigger({ type: "post", session: Get("session") });
    }
    submitTrigger({ type: "post" });
    // eslint-disable-next-line
	}, []);

  const buildItem = (field) => {
    const { type } = field;
    let labelTxt = field.label;

    switch (type) {
      case "label":
        if (field.style === "error") {
          if (field.link) {
            labelTxt = (
              <Link
                href={field.link}
                target="_blank"
                rel="noopener"
                color="inherit"
              >
                {labelTxt}
              </Link>
            );
          }
          return (
            <label
              className={clsx(styles["login-form-label"], {
                [styles["login-form-label-error"]]: field.style === "error",
              })}
            >
              {labelTxt}
            </label>
          );
        }
        if (field.link) {
          labelTxt = (
            <Link href={field.link} target="_blank" rel="noopener">
              {labelTxt}
            </Link>
          );
        }
        return <label className={styles["login-form-label"]}>{labelTxt}</label>;
      case "text":
        return (
          <InputForm
            type="text"
            value={form[field.name]}
            name={field.name}
            label={field.label}
            placeholder={t("input_field_placeholder", { text: field.label })}
            handleChange={formChange}
            required={true}
            disabled={loading}
          />
        );
      case "email":
        return (
          <InputForm
            type="email"
            value={form[field.name]}
            name={field.name}
            label={field.label}
            placeholder={t("input_field_placeholder", { text: field.label })}
            handleChange={formChange}
            required={true}
            disabled={loading}
          />
        );
      case "password":
        return (
          <InputForm
            type="password"
            value={form[field.name]}
            name={field.name}
            label={field.label}
            placeholder={t("input_field_placeholder", { text: field.label })}
            handleChange={formChange}
            required={true}
            disabled={loading}
          />
        );
      case "checkbox":
        if (field.link)
          labelTxt = (
            <Link to={field.link} target="_blank" rel="noopener">
              {labelTxt}
            </Link>
          );
        return (
          <CheckBox
            value={form[field.name]}
            name={field.name}
            label={labelTxt}
            handleChange={formChange}
            disabled={loading}
          />
        );
      case "oauth2":
        return (
          <div className={styles["form-oauth"]}>
            {loginOAuth2({ ...field })}
          </div>
        );
      default:
        return null;
    }
  };

  const buildList = () => {
    return flow.data.fields.map((field, idx) => {
      return (
        <div className={styles["item"]} key={idx}>
          {buildItem(field)}
        </div>
      );
    });
  };

  const buildBackBtn = () => {
    if (!flow.data.initial) {
      return (
        <Button
          iconPosition="start"
          onClick={() => submitTrigger({ type: "reset" })}
        >
          {t("btn_back")}
        </Button>
      );
    } else {
      return null;
    }
  };

  if (user === null || flow === null || session === null) {
    return (
      <InnerLayout
        breadcrumb={[
          {
            to: "/login",
            text: t("login_title"),
          },
        ]}
      >
        <PageHeader title={t("login_title")} subTitle={t("login_sub_title")} />
        <Loading />
      </InnerLayout>
    );
  }

  if (user && user.data) {
    return <Redirect to="/" />;
  }

  return (
    <InnerLayout
      breadcrumb={[
        {
          to: "/login",
          text: t("login_title"),
        },
      ]}
    >
      <PageHeader title={t("login_title")} subTitle={t("login_sub_title")} />
      <div className={styles["login-container"]}>
        <form
          className={styles["form"]}
          onSubmit={(e) => submitTrigger({ type: "submit" }, e)}
        >
          {buildList()}
          <div className={styles["login-btn-group"]}>
            {buildBackBtn()}
            <Button btnTypes="submit">{t("btn_next")}</Button>
          </div>
        </form>
      </div>
      <ToastContainer />
      {loading && <LoadingOverLay />}
    </InnerLayout>
  );
};

export default Login;
