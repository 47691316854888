import React from "react";
import { useTranslation } from "react-i18next";

//material ui
import { Box } from "@material-ui/core";

// component
import TypoGroup from "components/Typography/TypoGroup";

// style
import styles from "../Ncih.module.scss";

// img
import title from "assets/img/title-greeting.png";

const Purpose = () => {
  const { t } = useTranslation();

  return (
    <div className={styles["purpose"]}>
      <div className={styles["greeting-title"]}>
        <TypoGroup
          titleImg={title}
          title={t("ncih_greeting_title")}
          subtitle={t("ncih_greeting_sub_title")}
          variant="h2"
          maxWidth={332}
        />
      </div>
      <div className={styles["greeting-text"]}>
        <Box className={styles["greeting-text-inner"]}>
          <p>{t("ncih_greeting_text_1")}</p>
          <p>{t("ncih_greeting_text_2")}</p>
          <p>{t("ncih_greeting_text_3")}</p>
          <div className={styles["greeting-name-container"]}>
            <p>{t("ncih_greeting_date")}</p>
            <p
              className={styles["greeting-name"]}
              dangerouslySetInnerHTML={{ __html: t("ncih_greeting_name") }}
            ></p>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default Purpose;
