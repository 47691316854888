import React from "react";
import { useTranslation } from "react-i18next";

//material ui
import { Grid } from "@material-ui/core";

// components
import Btn from "components/Btns/Btn";
import TypoGroup from "components/Typography/TypoGroup";

// img
import title from "assets/img/title-members.png";
import img1 from "assets/img/img-member-01.png";
import img2 from "assets/img/img-member-02.png";
import img3 from "assets/img/img-member-03.png";
import img4 from "assets/img/img-member-04.png";
import img5 from "assets/img/img-member-05.png";
import img6 from "assets/img/img-member-06.png";
import img7 from "assets/img/img-member-07.png";
import img8 from "assets/img/img-member-08.png";
import img9 from "assets/img/img-member-09.png";

// style
import styles from "./Member.module.scss";

const Member = () => {
  const { t } = useTranslation();
  return (
    <div className={styles["member-container"]}>
      <div className={styles["member-title"]}>
        <TypoGroup
          titleImg={title}
          title={t("member_title")}
          subtitle={t("common_support_member_recruitment")}
          variant="h2"
          maxWidth={475}
          text={
            <span
              dangerouslySetInnerHTML={{ __html: t("index_suport_text") }}
            />
          }
        />
      </div>
      <div className={styles["member-btn-container"]}>
        <img
          className={styles["member-btn-img1"]}
          src={img1}
          alt={t("common_support_member_recruitment")}
        />
        <img
          className={styles["member-btn-img2"]}
          src={img2}
          alt={t("common_support_member_recruitment")}
        />
        <img
          className={styles["member-btn-img3"]}
          src={img3}
          alt={t("common_support_member_recruitment")}
        />
        <img
          className={styles["member-btn-img4"]}
          src={img4}
          alt={t("common_support_member_recruitment")}
        />
        <Grid className={styles["member-btn"]} container item justify="center">
          <Btn to="/member">{t("index_suport_btn")}</Btn>
        </Grid>
        <img
          className={styles["member-btn-img5"]}
          src={img5}
          alt={t("common_support_member_recruitment")}
        />
        <img
          className={styles["member-btn-img6"]}
          src={img6}
          alt={t("common_support_member_recruitment")}
        />
        <img
          className={styles["member-btn-img7"]}
          src={img7}
          alt={t("common_support_member_recruitment")}
        />
        <img
          className={styles["member-btn-img8"]}
          src={img8}
          alt={t("common_support_member_recruitment")}
        />
        <img
          className={styles["member-btn-img9"]}
          src={img9}
          alt={t("common_support_member_recruitment")}
        />
      </div>
    </div>
  );
};

export default Member;
