import React from "react";
import { useTranslation } from "react-i18next";

// utile
import { buildDate } from "utils/BuildDate";

// style
import styles from "./List.module.scss";

// img
import iconArrowBlack from "assets/img/icon-arrow-black.png";

const ListNews = ({ vals = null }) => {
  const { t } = useTranslation();

  const buildListItem = (val, index) => {
    return (
      <div className={styles["list-news-item"]} key={index}>
        {val.Short_Contents === "" && (
          <span className={styles["list-news-item-link"]}>
            <div className={styles["list-news-item-date"]}>
              <div className={styles["list-news-item-date-top"]}>
                {buildDate(parseInt(val.Published.unixms), "YYYY MM")}
              </div>
              <div>{buildDate(parseInt(val.Published.unixms), "DD")}</div>
            </div>
            <p className={styles["list-news-item-text"]}>{val.Title}</p>
          </span>
        )}
        {val.Short_Contents !== "" && (
          <a
            className={`${styles["list-news-item-link"]} ${styles["list-news-item-link-active"]}`}
            href={val.Short_Contents}
            rel="noreferrer"
            target="_blank"
          >
            <div className={styles["list-news-item-date"]}>
              <div className={styles["list-news-item-date-top"]}>
                {buildDate(parseInt(val.Published.unixms), "YYYY MM")}
              </div>
              <div>{buildDate(parseInt(val.Published.unixms), "DD")}</div>
            </div>
            <p className={styles["list-news-item-text"]}>{val.Title}</p>
            <img src={iconArrowBlack} alt={val.Title} />
          </a>
        )}
      </div>
    );
  };

  return (
    <div className={styles["list-news"]}>
      {vals.length !== 0 &&
        vals.map((val, index) => {
          return buildListItem(val, index);
        })}
      {vals.length === 0 && (
        <p className={styles["list-news-item__notfound"]}>
          {t("common_news_notfound")}
        </p>
      )}
    </div>
  );
};

export default ListNews;
