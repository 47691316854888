import React from "react";
import clsx from "clsx";

// style
import styles from "./Form.module.scss";

const SelectForm = ({
  handleChange = null,
  array = [{ label: "null", val: "null" }],
  value = null,
  classVal = null,
  fullwidth = false,
}) => {
  return (
    <div
      className={clsx(styles["form-select-container"], {
        [classVal]: classVal !== null,
        [styles["form-select-container-full"]]: fullwidth,
      })}
    >
      <select
        className={clsx(styles["form-select"], {
          [styles["form-select-full"]]: fullwidth,
        })}
        value={value}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
      >
        {array.map((val, index) => {
          return (
            <option key={index} value={val.val}>
              {val.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default SelectForm;
