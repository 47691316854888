import React, { useState } from "react";
import { useRest, useRestRefresh } from "@karpeleslab/react-klbfw-hooks";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// material ui
import { Box } from "@material-ui/core";

// component
import InnerLayout from "components/Layout/InnerLayout";
import PageHeader from "components/PageHeader/PageHeader";
import Helmet from "components/Helmet/Helmet";
import OrderList from "components/List/OrderList";
import Button from "components/Btns/Btn";
import StripeWrapper from "components/Payment/StripeWrapper";

// style
import styles from "./Order.module.scss";

const Order = () => {
  const { t } = useTranslation();
  const slug = useParams();
  const [orderProcess] = useRest(`Order/${slug.order}:process`);
  const cartRefresh = useRestRefresh("Catalog/Cart/@");
  const [user] = useRest("User:get");
  const [order, orderComp] = useState(false);

  const [load, setLoad] = useState(false);

  return (
    <InnerLayout
      breadcrumb={[
        {
          to: "",
          text: t("checkout_sub_title"),
        },
      ]}
    >
      <Helmet>
        <title>{`${t("checkout_sub_title")} - ${t("common_ncih")}`}</title>
        <meta
          property="og:title"
          content={`${t("checkout_sub_title")} - ${t("common_ncih")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("checkout_sub_title")} - ${t("common_ncih")}`}
        />
      </Helmet>
      <PageHeader
        title={t("checkout_title")}
        subTitle={t("checkout_sub_title")}
      />
      {order && (
        <Box className={`${styles["page-container"]}`}>
          <p className={styles["order-comp-text"]}>{t("order_comp_txt")}</p>
          <div className={styles["order-btn"]}>
            <Button to="/">{t("common_btn_top")}</Button>
          </div>
        </Box>
      )}
      {!order && (
        <>
          <Box className={`${styles["page-container"]}`}>
            {orderProcess !== null && user !== null && (
              <>
                <OrderList order={orderProcess} />
              </>
            )}
          </Box>
          {orderProcess !== null &&
            orderProcess.data.order.Status !== "completed" && (
              <Box className={`${styles["page-container"]}`}>
                <StripeWrapper
                  type="order"
                  setLoad={setLoad}
                  load={load}
                  orderProcess={orderProcess}
                  cartRefresh={cartRefresh}
                  orderComp={orderComp}
                />
              </Box>
            )}
        </>
      )}
    </InnerLayout>
  );
};

export default Order;
