import React from "react";

export const breakedText = (text) => {
  return text.split("¥n").map((line, index) => (
    <span key={index}>
      {line}
      <br />
    </span>
  ));
};
