import React from "react";
import { useTranslation } from "react-i18next";
import { useWindowWidth } from "@react-hook/window-size";
import { useRest } from "@karpeleslab/react-klbfw-hooks";

//material ui
import { Grid } from "@material-ui/core";

// components
import Btn from "components/Btns/Btn";
import TypoGroup from "components/Typography/TypoGroup";
import Tags from "components/Tags/Tags";
import Cards from "components/Cards/Cards";
import Loading from "components/Loading/Loading";

// img
import title from "assets/img/title-information.png";

// style
import styles from "./Information.module.scss";

// constant
import { INFORMATION_PAGE_COUNT_TOP, IMG_VARIATION } from "const/const";

const Hero = () => {
  const { t } = useTranslation();
  const onlyWidth = useWindowWidth();
  const [information] = useRest("Content/Cms/@information:search", {
    results_per_page: INFORMATION_PAGE_COUNT_TOP,
    sort: "published:desc",
    image_variation: IMG_VARIATION,
    query: {
      tag: "information",
    },
  });

  return (
    <div className={styles["information-container"]}>
      <div className={styles["information-title"]}>
        <TypoGroup
          titleImg={title}
          title={t("information_title")}
          subtitle={t("common_info")}
          variant="h2"
          maxWidth={367}
        />
      </div>
      <div className={styles["information-cards"]}>
        {information === null && <Loading />}
        {information !== null && (
          <Cards vals={information.data.data} path="information" />
        )}
      </div>
      {onlyWidth < 960 && (
        <Grid
          className={styles["information-btn"]}
          container
          item
          justify="center"
        >
          <Btn to="/information">{t("common_btn_more")}</Btn>
        </Grid>
      )}
      <Grid container justify="center" className={styles["information-tags"]}>
        <Tags path="information" />
      </Grid>
      {onlyWidth > 959 && (
        <Grid container item justify="center">
          <Btn to="/information">{t("common_btn_view_alll")}</Btn>
        </Grid>
      )}
    </div>
  );
};

export default Hero;
