import React from "react";
import { useTranslation } from "react-i18next";

//material ui
import { Box, Typography } from "@material-ui/core";

// component

// style
import styles from "../Member.module.scss";

const Fee = () => {
  const { t } = useTranslation();

  return (
    <Box className={`${styles["page-container"]} ${styles["member-benefits"]}`}>
      <Typography className={styles["member-title"]} variant="h2">
        {t("member_page_benefits")}
      </Typography>
      <p
        className={styles["member-benefits-text"]}
        dangerouslySetInnerHTML={{ __html: t("member_page_benefits_text") }}
      ></p>
    </Box>
  );
};

export default Fee;
