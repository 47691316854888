import React from "react";
import { useTranslation } from "react-i18next";

//material ui
import { Grid } from "@material-ui/core";
import { Box } from "@material-ui/core";

// component
import TypoGroup from "components/Typography/TypoGroup";
import List from "components/List/List";
import Btn from "components/Btns/Btn";
import BtnLink from "components/Btns/BtnLink";

// style
import styles from "../Ncih.module.scss";

// img
import title from "assets/img/title-ncih-acivities.png";
import img from "assets/img/img-ncih-activies.png";

const Activities = () => {
  const { t } = useTranslation();

  return (
    <div className={styles["activies"]}>
      <Box className={styles["page-container"]}>
        <TypoGroup
          titleImg={title}
          title={t("ncih_activies_title")}
          subtitle={t("ncih_activies_sub_title")}
          variant="h2"
          maxWidth={304}
          text={
            <span
              dangerouslySetInnerHTML={{
                __html: t("ncih_activies_title_text"),
              }}
            />
          }
          textAlign="left"
        />
        <Grid container>
          <Grid item xs={12} md={6} lg={6} className={styles["activies-img"]}>
            <img src={img} />
          </Grid>
          <Grid item xs={12} md={6} lg={6} className={styles["activies-text"]}>
            <div className={styles["activies-list"]}>
              <List
                text={[
                  t("ncih_activies_list_item_1"),
                  t("ncih_activies_list_item_2"),
                  t("ncih_activies_list_item_3"),
                  t("ncih_activies_list_item_4"),
                  t("ncih_activies_list_item_5"),
                  t("ncih_activies_list_item_6"),
                  t("ncih_activies_list_item_7"),
                  t("ncih_activies_list_item_8"),
                  t("ncih_activies_list_item_9"),
                  t("ncih_activies_list_item_10"),
                ]}
              />
            </div>
            <div className={styles["activies-btn"]}>
              <Btn
                to="http://www.japansmeijiindustrialrevolution.com/history/"
                icon="blank"
              >
                {t("ncih_activies_btn_1")}
              </Btn>
            </div>
            <div className={styles["activies-btn"]}>
              <BtnLink to="https://www.ihic.jp">
                {t("ncih_activies_btn_2")}
              </BtnLink>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Activities;
