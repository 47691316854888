import React from "react";
import { useTranslation } from "react-i18next";

//material ui
import { Box } from "@material-ui/core";

// component
import InnerLayout from "components/Layout/InnerLayout";
import PageHeader from "components/PageHeader/PageHeader";
import Btn from "components/Btns/Btn";
import ListBtnGroup from "components/Btns/ListBtnGroup";
import Helmet from "components/Helmet/Helmet";
import Purpose from "./section/Purpose";
import Activities from "./section/Activities";
import Greeting from "./section/Greeting";

// style
import styles from "./Ncih.module.scss";

// img
import PageHeaderImg from "assets/img/img-page-header-about.png";

const Ncih = () => {
  const { t } = useTranslation();

  return (
    <InnerLayout
      breadcrumb={[
        {
          to: "/ncih",
          text: t("ncih_sub_title"),
        },
      ]}
    >
      <Helmet>
        <title>{`${t("ncih_sub_title")} - ${t("common_ncih")}`}</title>
        <meta
          property="og:title"
          content={`${t("ncih_sub_title")} - ${t("common_ncih")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("ncih_sub_title")} - ${t("common_ncih")}`}
        />
      </Helmet>
      <PageHeader
        title={t("ncih_title")}
        subTitle={t("ncih_sub_title")}
        bgImg={PageHeaderImg}
      />
      <Box className={styles["page-container"]}>
        <p
          className={styles["about-text"]}
          dangerouslySetInnerHTML={{ __html: t("ncih_about_text") }}
        />
        <div
          className={`${styles["btn-group--center"]} ${styles["about-btn"]}`}
        >
          <Btn to="http://www.japansmeijiindustrialrevolution.com/history/">
            {t("ncih_about_btn")}
          </Btn>
        </div>
        <div className={styles["about-btn-group"]}>
          <ListBtnGroup
            btns={[
              {
                text: t("ncih_founder_sub_title"),
                to: "/ncih/founder",
              },
              {
                text: t("ncih_organizational_sub_title"),
                to: "/ncih/organizational",
              },
            ]}
          />
        </div>
      </Box>
      <Purpose />
      <Activities />
      <Greeting />
    </InnerLayout>
  );
};

export default Ncih;
