import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import qs from "qs";

const RedirectPage = () => {
  const location = useLocation();
  const hash = qs.parse(location.search.split("?")[1]);

  return <Redirect to={hash.path === void 0 ? "/" : `/${hash.path}`} />;
};

export default RedirectPage;
