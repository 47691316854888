import React from "react";
import { useTranslation } from "react-i18next";

//material ui
import { Typography } from "@material-ui/core";

// component
import InnerLayout from "components/Layout/InnerLayout";
import PageHeader from "components/PageHeader/PageHeader";
import Helmet from "components/Helmet/Helmet";
import ContactInfo from "components/ContactInfo/ContactInfo";

// style
import styles from "./Privacy.module.scss";

const News = () => {
  const { t } = useTranslation();

  return (
    <InnerLayout
      breadcrumb={[
        {
          to: "/privacy",
          text: t("privacy_sub_title"),
        },
      ]}
    >
      <Helmet>
        <title>{`${t("privacy_sub_title")} - ${t("common_ncih")}`}</title>
        <meta
          property="og:title"
          content={`${t("privacy_sub_title")} - ${t("common_ncih")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("privacy_sub_title")} - ${t("common_ncih")}`}
        />
      </Helmet>
      <PageHeader
        title={t("privacy_title")}
        subTitle={t("privacy_sub_title")}
      />

      <div className={`${styles["privacy-container"]}`}>
        <Typography className={styles["privacy-title"]} variant="h2">
          {t("privacy_page_title_1")}
        </Typography>
        <p
          className={styles["privacy-text"]}
          dangerouslySetInnerHTML={{ __html: t("privacy_page_text_01") }}
        ></p>
        <p className={styles["privacy-text"]}>{t("privacy_page_text_02")}</p>
        <p
          className={styles["privacy-text"]}
          dangerouslySetInnerHTML={{ __html: t("privacy_page_text_03") }}
        />
        <Typography className={styles["privacy-title"]} variant="h2">
          {t("privacy_page_title_2")}
        </Typography>
        <p className={styles["privacy-text"]}>{t("privacy_page_text_04")}</p>
        <p
          className={`${styles["privacy-text-right"]} ${styles["privacy-text"]}`}
        >
          {t("privacy_page_under")}
        </p>
      </div>
      <ContactInfo />
    </InnerLayout>
  );
};

export default News;
