import React from "react";
import { useTranslation } from "react-i18next";

// component
import InnerLayout from "components/Layout/InnerLayout";
import PageHeader from "components/PageHeader/PageHeader";
import Helmet from "components/Helmet/Helmet";

import Benefits from "./section/Benefits";
import Fee from "./section/Fee";
import Payment from "./section/Payment";
import ContactInfo from "components/ContactInfo/ContactInfo";

// img
import PageHeaderImg from "assets/img/img-page-header-member.png";

const Member = () => {
  const { t } = useTranslation();

  return (
    <InnerLayout
      breadcrumb={[
        {
          to: "/member",
          text: t("member_sub_title"),
        },
      ]}
    >
      <Helmet>
        <title>{`${t("member_sub_title")} - ${t("common_ncih")}`}</title>
        <meta
          property="og:title"
          content={`${t("member_sub_title")} - ${t("common_ncih")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("member_sub_title")} - ${t("common_ncih")}`}
        />
      </Helmet>
      <PageHeader
        title={t("member_title")}
        subTitle={t("member_sub_title")}
        bgImg={PageHeaderImg}
      />
      <Benefits />
      <Fee />
      <Payment />
      <ContactInfo />
    </InnerLayout>
  );
};

export default Member;
