import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useRest, useRestRefresh } from "@karpeleslab/react-klbfw-hooks";

// component
import InnerLayout from "components/Layout/InnerLayout";
import PageHeader from "components/PageHeader/PageHeader";
import Helmet from "components/Helmet/Helmet";
import Card from "components/Cards/Card";
import Pagination from "components/Pagination/Pagination";
import Tags from "components/Tags/Tags";
import Loading from "components/Loading/Loading";

// styles
import styles from "./Information.module.scss";

// constant
import { INFORMATION_PAGE_COUNT, IMG_VARIATION } from "const/const";

const InformationList = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { tag, pageNum } = useParams();

  // page
  const [currentPage, setCurrentPage] = useState(
    pageNum === void 0 ? 1 : pageNum
  );

  // tag
  const [tagParam, setTagParam] = useState(
    tag === void 0 ? "information" : tag
  );

  const [infomation, setInformation] = useRest(
    "Content/Cms/@information:search",
    {
      page_no: currentPage,
      results_per_page: INFORMATION_PAGE_COUNT,
      sort: "published:desc",
      image_variation: IMG_VARIATION,
      query: {
        tag: tagParam.replace("-", "・"),
      },
    }
  );

  const informationRefresh = useRestRefresh("Content/Cms/@information:search", {
    page_no: currentPage,
    results_per_page: INFORMATION_PAGE_COUNT,
    sort: "published:desc",
    image_variation: IMG_VARIATION,
    query: {
      tag: tagParam.replace("-", "・"),
    },
  });

  useEffect(() => {
    setInformation(null);
    setCurrentPage(pageNum === void 0 ? 1 : pageNum);
    setTagParam(tag === void 0 ? "information" : tag);
  }, [location.pathname, pageNum, tag]);

  useEffect(() => {
    if (infomation === null) {
      informationRefresh();
    }
  }, [currentPage, infomation, informationRefresh]);

  return (
    <InnerLayout
      breadcrumb={[
        {
          to: "/information",
          text: t("information_sub_title"),
        },
      ]}
    >
      <Helmet>
        <title>{`${t("information_sub_title")} - ${t("common_ncih")}`}</title>
        <meta
          property="og:title"
          content={`${t("information_sub_title")} - ${t("common_ncih")}`}
        />
        <meta
          property="og:site_name"
          content={`${t("information_sub_title")} - ${t("common_ncih")}`}
        />
      </Helmet>
      <PageHeader
        title={t("information_title")}
        subTitle={t("information_sub_title")}
      />
      <Tags tag={tag} path="information" />

      {infomation === null && <Loading />}
      {infomation !== null && infomation?.data.data.length === 0 && (
        <div className={styles["information-list__notfound"]}>
          <p>{t("common_information_notfound")}</p>
        </div>
      )}
      {infomation !== null && infomation?.data.data.length !== 0 && (
        <div className={styles["information-list"]}>
          {infomation.data.data.map((val, index) => {
            return (
              <Card key={index} path="information" val={val} type="list" />
            );
          })}
        </div>
      )}
      {infomation !== null && infomation?.data.data.length !== 0 && (
        <Pagination
          setCurrentPage={setCurrentPage}
          defaultPage={currentPage}
          path={tagParam === "information" ? "" : `/tag-${tagParam}`}
          count={infomation?.paging.page_max}
        />
      )}
    </InnerLayout>
  );
};

export default InformationList;
